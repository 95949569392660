<template>
  <div
    class="column medium-4 large-2"
    :style="{
      visibility: $store.state.authenticated ? 'visible' : 'hidden',
    }"
  >
    <div
      class="dleft"
      id="leftpanel"
      data-toggler=""
      data-animate="hinge-in-from-top hinge-out-from-top"
      aria-expanded="true"
    >
      <div class="mlogo">
        <div class="mlogo-sm">
          <a href="">
            <img
              src="https://www.pesapal.com/images/mlogo.jpg"
              alt="Logo"
              class="boxgrad"
            />
          </a>
        </div>
        <h4>
          <a href="#">{{ merchantName }}</a>
        </h4>
      </div>
      <div id="sidemenu" ref="sidemenu">
        <div>
          <ul
            id="sidenav"
            class="vertical menu"
            role="menu"
            aria-multiselectable="true"
            data-accordion-menu
          >
            <li role="menuitem">
              <a
                href="https://www.pesapal.com/dashboard/merchant/merchantdashboard"
              >
                Business Dashboard
              </a>
            </li>
            <li role="menuitem">
              <a
                href="https://www.pesapal.com/dashboard/merchant/merchanttransactions/showtransactions"
              >
                Transaction History
              </a>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a href="">Withdraw funds</a>
              <ul
                class="menu vertical nested submenu is-accordion-submenu"
                data-accordion-menu
              >
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantwithdrawals/withdrawfunds"
                  >
                    Withdraw Funds
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantpaymentoptions/viewbankoptions"
                  >
                    Bank Details
                  </a>
                </li>
                <li role="menuitem">
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantwithdrawals/automaticwithdrawsettings"
                  >
                    Automatic Withdraw
                  </a>
                </li>
              </ul>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a href="https://mpos.pesapal.com/merchantsettings">POS</a>
              <ul
                class="menu vertical nested submenu is-accordion-submenu"
                data-accordion-menu
              >
                <li>
                  <a href="https://mpos.pesapal.com/merchantsettings">
                    Branches
                  </a>
                </li>
                <li>
                  <a href="https://mpos.pesapal.com/merchantsettings/settings">
                    Settings
                  </a>
                </li>
              </ul>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a href="/">Loans</a>
              <ul
                class="menu vertical nested submenu is-accordion-submenu"
                data-accordion-menu
              >
                <li>
                  <router-link to="/calculator">Loan Calculator</router-link>
                </li>

                <li v-if="$store.getters.isActive">
                  <router-link to="/apply">Apply for a loan</router-link>
                </li>
                <li>
                  <router-link to="/history">Loan History</router-link>
                </li>
                <li>
                  <router-link to="/payments">Payments</router-link>
                </li>
              </ul>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a class="@dashclass" href="#">Invoices</a>
              <ul class="menu vertical nested submenu is-accordion-submenu">
                <li role="menuitem">
                  <a class="@dclass" href="#" onclick="">Create New Invoice</a>
                </li>
                <li role="menuitem">
                  <a class="@active" href="#">Create Bulk Invoices</a>
                </li>
              </ul>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a href="">Simple Selling</a>
              <ul
                class="menu vertical nested submenu is-accordion-submenu"
                data-accordion-menu
              >
                <li>
                  <a
                    href="https://www.pesapal.com/simpleselling/simpleselling/viewitems"
                  >
                    My Store
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pesapal.com/simpleselling/simpleselling/additem"
                  >
                    Add new Item
                  </a>
                </li>
              </ul>
            </li>
            <li role="menuitem" class="is-accordion-submenu-parent">
              <a href="">Account Settings</a>
              <ul
                class="menu vertical nested submenu is-accordion-submenu"
                data-accordion-menu
              >
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantdashboard/editmembership"
                  >
                    Edit Profile
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantdashboard/editlogo"
                  >
                    Edit Logo
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/account/changepassword"
                  >
                    Change Password
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantusers"
                  >
                    Manage Users
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pesapal.com/dashboard/merchant/merchantipn"
                  >
                    IPN settings
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  computed: {
    merchantName() {
      return (
        this.$store &&
        this.$store.getters &&
        this.$store.getters.getMerchantName
      );
    },
  },
};
</script>
