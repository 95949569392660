<template>
  <div class="loanapp">
    <div class="callout warning" v-if="errorMessage.length">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>

      <p>{{ errorMessage }}</p>
      <span class="close close-button" @click="clearErrors">x</span>
    </div>
    <div class="row medium-uncollapse">
      <div class="column medium-6 pdleft">
        <h2 class="pageTitle">Loan Application</h2>
        <p v-if="step == 1">Please fill the form below:</p>
        <ul id="progressbar" class="progress-indicator">
          <li
            class="is-complete"
            :id="'loan-particulars' + stepone"
            data-step="1"
          >
            Loan Particulars
          </li>
          <li class="is-current" :id="'confirmation' + steptwo" data-step="2">
            Confirmation
          </li>
        </ul>
        <fieldset class="form-horizontal">
          <div id="loan-particulars" v-if="step == 1">
            <div class="control-group">
              <label class="control-label"
                >Loan Type: <span class="required">*</span></label
              >
              <div class="controls">
                <select v-model="loan.product_id">
                  <option
                    v-for="product in products"
                    v-bind:value="product.id"
                    v-bind:key="product.id"
                  >
                    {{ product.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="control-group">
              <label class="control-label"
                >Amount Applied: <span class="required">*</span></label
              >
              <div class="controls">
                <input
                  :class="amountClass"
                  v-model="formattedAmount"
                  type="text"
                  @blur="calculateSchedule"
                  @input="formatAmount"
                />
                <span class="error">{{ amountError }}</span>
              </div>
            </div>
            <div class="control-group">
              <label class="control-label"
                >Purpose of Loan: <span class="required">*</span></label
              >
              <div class="controls">
                <input
                  :class="purposeClass"
                  v-model="loan.purpose"
                  type="text"
                  @blur="clearErrors"
                />
                <span class="error">{{ purposeError }}</span>
              </div>
            </div>
            <div class="control-group">
              <label class="control-label"
                >Repayment Period: <span class="required">*</span></label
              >
              <div class="controls">
                <select v-model="loan.duration" @change="calculateSchedule">
                  <option value="1" v-if="maxLoanTenure >= 1">1 Month</option>
                  <option value="2" v-if="maxLoanTenure >= 2">2 Months</option>
                  <option value="3" v-if="maxLoanTenure >= 3">3 Months</option>
                  <option value="4" v-if="maxLoanTenure >= 4">4 Months</option>
                  <option value="5" v-if="maxLoanTenure >= 5">5 Months</option>
                  <option value="6" v-if="maxLoanTenure >= 6">6 Months</option>
                </select>
              </div>
            </div>
            <div class="control-group">
              <label class="control-label">Board Resolution: </label>
              <div class="controls">
                <div>
                  <input
                    type="file"
                    ref="file"
                    :class="resolutionClass"
                    v-on:change="handleFileUpload"
                  />
                </div>
                <span class="error">{{ resolutionError }}</span>
              </div>
            </div>
            <div class="control-group">
              <label class="control-label">Monthly installments:</label>
              <label class="controls"
                ><b>{{ monthlyInstallment }}</b></label
              >
            </div>
            <p>
              &nbsp;
              <!-- spacer -->
            </p>
            <p>
              <BaseCheckbox
                v-model="confirmInfo"
                label="By submitting this form, you acknowledge that the information
                given herein is true to the best of your knowledge and belief.
                You further authorize Pesapal to obtain any information from
                yourselves and/or third parties to verify the information
                provided."
              />
            </p>
            <p>
              <span style="display: table-cell; padding-right: 5px"
                ><input type="checkbox" v-model="agreeTerms"
              /></span>
              <span
                style="
                  display: table-cell;
                  text-align: justify;
                  font-family: 'Ubuntu';
                  color: #414141;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 13px;
                "
                >I have read the
                <a href="#" style="color: #16659e; font-weight: 500"
                  >Terms & Conditions</a
                >
                for this service</span
              >
            </p>
            <div class="control-group">
              <div class="controls">
                <input
                  type="button"
                  class="button primary"
                  value="SUBMIT"
                  :disabled="!(confirmInfo && agreeTerms)"
                  @click="submitLoanApplication"
                />
              </div>
            </div>
          </div>
          <div id="confirmation" v-if="step == 2">
            <p>
              &nbsp;
              <!-- spacer -->
            </p>
            <h4>Congratulations!!!</h4>
            <p>
              &nbsp;
              <!-- spacer -->
            </p>
            <p>
              Your loan application <b>#{{ applicationId }}</b> is being
              processed. We will be in touch within one business day.
            </p>
            <p>
              &nbsp;
              <!-- spacer -->
            </p>
            <div class="control-group">
              <div class="controls">
                <input
                  type="button"
                  class="button primary"
                  value="LOAN DASHBOARD"
                  @click.prevent="loadDashboard"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="column medium-6 pdright">
        <h2 class="pageTitle">Company Details</h2>
        <div class="companyD" v-if="$store">
          <p>
            <span class="label-comdetails">Merchant ID:</span>
            <span class="comdetails">{{
              $store.getters.getCurrentUser.merchant_details.external_id
            }}</span>
          </p>
          <p>
            <span class="label-comdetails">Merchant Name:</span>
            <span class="comdetails">{{ $store.getters.getMerchantName }}</span>
          </p>
          <p>
            <span class="label-comdetails">Country Code:</span>
            <span class="comdetails">{{
              $store.getters.getCurrentUser.merchant_details.country_code
            }}</span>
          </p>
          <p>
            <span class="label-comdetails">Mobile Number:</span>
            <span class="comdetails">{{
              $store.getters.getCurrentUser.merchant_details.phone_number
            }}</span>
          </p>
          <p>
            <span class="label-comdetails">Email:</span>
            <span class="comdetails">{{
              $store.getters.getCurrentUser.merchant_details.email
            }}</span>
          </p>
          <div
            v-if="
              $store.getters.getCurrentUser.merchant_details.directors.length
            "
          ></div>
        </div>
        <br />
        <span>You qualify for a loan of</span>
        <h3>{{ $store && $store.getters.getCurrency }} {{ creditLimitFmt }}</h3>
        <br />
        <div class="btnwrap medium-6">
          <router-link to="/loaninfo" class="button primary"
            >SEE HOW LOANS WORK</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileService from '../services/FileService';
import LoanService from '../services/LoanService';
import Util from '../util/Util';

export default {
  name: 'LoanApplication',
  data() {
    return {
      loading: false,
      formattedAmount: null,
      file: null,
      creditLimit: 0,
      creditLimitFmt: 0,
      maxLoanTenure: 0,
      applicationId: '8994775', //default
      directors: [],
      products: [],
      step: 1,
      stepone: '_stepone',
      steptwo: '',
      loan: {
        amount: '',
        purpose: '',
        duration: 3,
        product_id: 0,
        customer_id:
          this.$store &&
          this.$store.state &&
          this.$store.state.currentUser.merchant_details
            ? this.$store.state.currentUser.merchant_details.external_id
            : null,
      },
      confirmInfo: false,
      agreeTerms: false,
      errorMessage: '',
      amountError: '',
      purposeError: '',
      resolutionError: '',
      monthlyInstallment: '',
      amountClass: 'input-block-level',
      purposeClass: 'input-block-level',
      resolutionClass: 'input-block-level',
    };
  },
  mounted() {
    if (this.$store && this.$store.getters) {
      if (this.$store.getters.getCreditLimit) {
        this.creditLimit = this.$store.getters.getCreditLimit;
        this.creditLimitFmt = Util.formatNumber(this.creditLimit);
      }
      if (this.$store.getters.getMaxLoanTenure) {
        this.maxLoanTenure = this.$store.getters.getMaxLoanTenure;
      }
      if (this.$store.getters.getLoanAmount) {
        this.formattedAmount = this.$store.getters.getLoanAmount;
        this.formatAmount();
        this.calculateSchedule();
        this.validateCreditLimit();
        this.$store.dispatch('setLoanAmount', null);
      }
    }
    this.getLoanProducts();
    //Doing this to get the tests to pass. We need to modify the tests and mock the store
    this.$store &&
      this.$store.dispatch('setCurrentBreadcrumb', 'Loan Application');
  },
  methods: {
    formatAmount() {
      if (this.formattedAmount) {
        this.formattedAmount = this.formattedAmount.replaceAll(',', '');
        this.formattedAmount = Util.formatNumber(this.formattedAmount);
      }
      this.loan.amount = parseFloat(this.formattedAmount.replaceAll(',', ''));
    },
    calculateSchedule() {
      this.amountError = '';
      this.amountClass = 'input-block-level';
      if (this.loan.amount && !isNaN(this.loan.amount)) {
        let request = {
          amount: this.loan.amount,
          duration: this.loan.duration,
        };
        LoanService.calculateSchedule(request, false)
          .then((response) => {
            if (response.data) {
              this.monthlyInstallment = Util.formatNumber(
                response.data.repayment_schedule[0].total_outstanding,
                response.data.currency
              );
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.errorMessage = error.response.data.message;
            } else {
              this.errorMessage = error.message;
            }
          });
      }
    },
    getLoanProducts() {
      LoanService.getLoanProducts().then(({ data }) => {
        this.products = data;
      });
    },
    validate() {
      this.clearErrors();
      var valid = true;
      if (!this.loan.amount) {
        this.amountError = 'Please provide loan amount';
        this.amountClass += ' required';
        return false;
      }
      if (isNaN(this.loan.amount)) {
        this.amountError = 'Loan amount must be a numeric value';
        this.amountClass += ' required';
        valid = false;
      }
      if (!this.loan.purpose) {
        this.purposeError = 'Please state the loan purpose';
        this.purposeClass += ' required';
        valid = false;
      }
      if (!this.validateCreditLimit()) {
        valid = false;
      }
      if (this.file === null) {
        /*this.resolutionClass += ' required';
        this.resolutionError =
          'A board resolution is required for this loan application';
        valid = false;*/
      }
      if (!this.confirmInfo) {
        this.errorMessage =
          'Please acknowledge that the information is true in order to proceed';
        valid = false;
      }
      if (!this.agreeTerms) {
        this.errorMessage =
          'Please accept the Terms & Conditions in order to proceed';
        valid = false;
      }
      return valid;
    },
    validateCreditLimit() {
      if (this.loan.amount > this.creditLimit) {
        this.amountError = `Your loan limit is ${this.creditLimitFmt}`;
        this.amountClass += ' required';
        return false;
      }
      return true;
    },
    submitLoanApplication() {
      if (this.validate()) {
        // if (!this.$store.getters.getBoardResolutionPath) {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('type', 'resolution');
        FileService.upload(formData).then(({ file_path }) => {
          this.loan.board_resolution_path = file_path;
          this.submitLoan();
        });
        // } else {
        //   this.submitLoan();
        // }
      }
    },
    submitLoan() {
      LoanService.submitLoan(this.loan)
        .then(({ data }) => {
          this.step = 2;
          this.steptwo = '_steptwo';
          this.applicationId = data.id;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data && error.response.data.message) {
              this.errorMessage = error.response.data.message;
            } else if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors[0]
            ) {
              this.errorMessage = error.response.data.errors[0];
            } else {
              this.errorMessage =
                'The server returned an error. Please contact your System Administrator';
            }
          }
        });
    },
    loadDashboard() {
      this.$router.push('/');
    },
    clearErrors() {
      this.errorMessage = '';
      this.amountError = '';
      this.purposeError = '';
      this.resolutionError = '';
      this.amountClass = 'input-block-level';
      this.purposeClass = 'input-block-level';
      this.resolutionClass = 'input-block-level';
    },
    handleFileUpload(event) {
      this.clearErrors();
      this.file = event.target.files[0];
    },
  },
};
</script>

<style scoped>
.error {
  margin-bottom: -1.3rem;
  position: absolute;
  font-size: 0.8.5rem;
}
</style>
