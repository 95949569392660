<template>
  <div class="loancalc">
    <div
      class="callout warning"
      v-if="$store.getters.isAuthenticated && !$store.getters.isActive"
    >
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <p>Your account is blocked.</p>
    </div>
    <div class="row medium-uncollapse">
      <div class="column medium-6 pdleft">
        <h2 class="pageTitle">Loans</h2>
        <h4>Loan Calculator</h4>
        <p>
          Select the amount needed and the payment period for up to 6 months
        </p>
        <table class="loancalc">
          <tr>
            <td>
              <input
                placeholder="Loan Amount"
                class="input-block-level"
                v-model="amount"
                type="text"
              />
            </td>
            <td>
              <select v-model="duration">
                <option value="1">1 Month</option>
                <option value="2">2 Months</option>
                <option value="3">3 Months</option>
                <option value="4">4 Months</option>
                <option value="5">5 Months</option>
                <option value="6">6 Months</option>
              </select>
            </td>
          </tr>
        </table>
        <input
          value="CALCULATE"
          class="btn secondary button"
          type="button"
          @click="calculateSchedule"
        />
      </div>
      <div class="column medium-6 pdright">
        <p>You qualify for a loan of</p>
        <h3>{{ $store.getters.getCurrency }} {{ creditLimit }}</h3>
        <br />
        <table class="summary">
          <tr>
            <th>Loan Amount:</th>
            <td>
              {{ loanAmount }}
            </td>
          </tr>
          <tr>
            <th>Duration:</th>
            <td>{{ duration }} Months</td>
          </tr>
          <tr>
            <th>Interest:</th>
            <td>
              {{ interestAmount }}
            </td>
          </tr>
          <tr>
            <th>Monthly Payment:</th>
            <td>
              {{ monthlyInstallment }}
            </td>
          </tr>
        </table>
        <router-link
          to="/apply"
          class="button alert"
          v-if="$store.getters.isActive"
          >APPLY NOW</router-link
        >
      </div>
      <hr />
      <div class="row medium-uncollapse loan-detail">
        <div class="column medium-6 pdleft noborder" v-if="repaymentSchedule">
          <h4 class="mrgtb-16">Expected Repayment Schedule</h4>

          <table class="schedule">
            <thead>
              <tr>
                <th class="sorting">Installment Amount ({{ currency }})</th>
                <th class="sorting">Due Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(installment, index) in repaymentSchedule"
                :key="index"
              >
                <td class="number">
                  {{ installment.total_installment_for_period }}
                </td>
                <td>{{ installment.due_date }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="btnwrap medium-6">
          <router-link to="/loaninfo" class="button primary"
            >SEE HOW LOANS WORK</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanService from '../services/LoanService';
import Util from '../util/Util';

export default {
  name: 'LoanCalculator',
  data() {
    return {
      amount: null,
      duration: 3,
      currency: null,
      creditLimit: 0,
      repaymentSchedule: null,
      monthlyInstallment: null,
      interestAmount: null,
    };
  },
  computed: {
    loanAmount() {
      return this.amount ? Util.formatNumber(this.amount) : null;
    },
  },
  mounted() {
    if (this.$store.getters.getCreditLimit) {
      this.creditLimit = Util.formatNumber(this.$store.getters.getCreditLimit);
    }
    this.$store.dispatch('setCurrentBreadcrumb', 'Loan Calculator');
  },
  methods: {
    calculateSchedule() {
      if (this.amount && !isNaN(this.amount)) {
        let request = {
          amount: this.amount,
          duration: this.duration,
        };
        LoanService.calculateSchedule(request)
          .then(({ data }) => {
            if (data) {
              this.currency = data.currency;
              this.repaymentSchedule = data.repayment_schedule;
              this.monthlyInstallment = Util.formatNumber(
                data.repayment_schedule[0].total_outstanding
              );
              var interest = 0;
              this.repaymentSchedule.forEach((x) => {
                interest += x.interest_due;
                x.interest_due = Util.formatNumber(x.interest_due);
                x.principal_due = Util.formatNumber(x.principal_due);
                x.total_installment_for_period = Util.formatNumber(
                  x.total_outstanding
                );
                this.interestAmount = Util.formatNumber(interest);
              });
              this.$store.dispatch('setLoanAmount', this.amount);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              this.errorMessage = error.response.data.message;
            } else {
              this.errorMessage = error.message;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
table.loancalc td {
  width: 50%;
  padding: 20px 30px 20px 0px;
  border-width: 0px;
}
.noborder {
  border-width: 0px;
}
table.summary {
  width: auto;
  border-width: 0px;
}
table.summary th:first-child {
  text-align: right;
  color: #868686;
}
table.summary th,
table.summary td {
  border-width: 0px;
  padding: 5px;
}
table.schedule td {
  width: 50%;
}
</style>
