<template>
  <div class="loanprofile">
    <h2 class="pageTitle">Loan Profile</h2>
    <h4 class="pageTitle">Merchant Details</h4>
    <div
      v-if="
        $store.getters.getCurrentUser &&
        $store.getters.getCurrentUser.merchant_details
      "
      class="companyD"
    >
      <p>
        <span class="label-comdetails">Merchant ID:</span>
        <span class="comdetails">{{
          $store.getters.getCurrentUser.merchant_details.external_id
        }}</span>
      </p>
      <p>
        <span class="label-comdetails">Merchant Name:</span>
        <span class="comdetails">{{ $store.getters.getMerchantName }}</span>
      </p>
      <p>
        <span class="label-comdetails">Country Code:</span>
        <span class="comdetails">{{
          $store.getters.getCurrentUser.merchant_details.country_code
        }}</span>
      </p>
      <p>
        <span class="label-comdetails">Mobile Number:</span>
        <span class="comdetails">{{
          $store.getters.getCurrentUser.merchant_details.phone_number
        }}</span>
      </p>
      <p>
        <span class="label-comdetails">Email:</span>
        <span class="comdetails">{{
          $store.getters.getCurrentUser.merchant_details.email
        }}</span>
      </p>
    </div>
    -->
    <h2 class="pageTitle">Directors</h2>
    <table>
      <thead>
        <tr>
          <th class="sorting">Name</th>
          <th class="sorting">Designation</th>
          <th class="sorting">Nature of Ownership</th>
          <th class="sorting">ID Number</th>
          <th class="sorting" v-if="$store.getters.isActive">ID</th>
          <th class="sorting" v-if="$store.getters.isActive">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="director in this.$store.getters.getDirectors"
          :key="director.id"
        >
          <td>{{ director.name }}</td>
          <td>{{ director.designation }}</td>
          <td>{{ director.nature_of_ownership }}</td>
          <td>{{ director.id_number }}</td>
          <td v-if="$store.getters.isActive">
            <a :href="director.identity_document_url" target="_blank"
              >download</a
            >
          </td>
          <td v-if="$store.getters.isActive">
            <a @click.prevent="() => directorForm(director)">Edit</a> |
            <a @click.prevent="() => deleteDirector(director.id)">Delete</a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="control-group">
      <label class="control-label">&nbsp;</label>
      <div class="controls">
        <a
          @click.prevent="() => directorForm(null)"
          name="add-director"
          v-if="$store.getters.isActive"
          >Add Director</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';
import MerchantService from '../services/MerchantService';

export default {
  name: 'LoanProfile',
  methods: {
    directorForm(director) {
      this.$store.dispatch('setCurrentDirector', director);
      this.$router.push('/director');
    },
    deleteDirector(id) {
      //TODO: Add confirmation logic here
      MerchantService.deleteDirector(id).then(() => {
        AuthService.me().then((data) => {
          this.$store.dispatch('setCurrentUser', data);
        });
      });
    },
  },
  created() {
    this.$store.dispatch('setCurrentBreadcrumb', 'Loan Profile');
  },
};
</script>
