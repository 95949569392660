<template>
  <div class="controls input-group">
    <span
      style="
        display: table-cell;
        padding-right: 5px;
        padding-top: 3px;
        vertical-align: top;
      "
    >
      <input
        class="input-block-level input-large"
        v-bind="$attrs"
        type="checkbox"
        :id="uuid"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
    </span>
    <span style="display: table-cell; text-align: justify">
      <label
        class="control-label"
        style="text-align: justify"
        :for="uuid"
        v-if="label"
        >{{ label }}</label
      >
    </span>
  </div>
</template>

<script>
import UniqueID from '../../features/UniqueIDs';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>
