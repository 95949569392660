import request from '@/util/request';

function submitLoan(data) {
  return request({
    url: '/api/merchant/loans/request',
    method: 'post',
    data,
  });
}

function calculateSchedule(data, showLoading = true) {
  request.showLoading = showLoading;
  return request({
    url: '/api/merchant/loans/loan_schedule',
    method: 'post',
    data,
  });
}

function getMerchantCurrentRequest(externalId) {
  return request({
    url: `/api/merchant/loans/current_request/${externalId}`,
    method: 'get',
  });
}

function getLoanRequestById(id) {
  return request({
    url: `/api/merchant/loans/request/${id}`,
    method: 'get',
  });
}

function getMerchantLoanRequests(externalId) {
  return request({
    url: `/api/merchant/loans/requests/${externalId}`,
    method: 'get',
  });
}

function getLoanDetails(loanId) {
  return request({
    url: `/api/merchant/loans/${loanId}`,
    method: 'get',
  });
}

function getLoanForeclosureDetails(loanId, query) {
  return request({
    url: `/api/merchant/loans/${loanId}/foreclosure`,
    method: 'get',
    params: query,
  });
}

function getLoanRepaymentRequests(loanId) {
  return request({
    url: `/api/merchant/loans/${loanId}/repayment_requests`,
    method: 'get',
  });
}

function submitOfflineRepayment(data) {
  return request({
    url: '/api/merchant/loans/offline_repayment',
    method: 'post',
    data,
  });
}

function getLoanProducts() {
  return request({
    url: '/api/merchant/loans/products',
    method: 'get',
  });
}

export default {
  submitLoan,
  getLoanDetails,
  calculateSchedule,
  getLoanRequestById,
  submitOfflineRepayment,
  getMerchantLoanRequests,
  getLoanRepaymentRequests,
  getMerchantCurrentRequest,
  getLoanForeclosureDetails,
  getLoanProducts,
};
