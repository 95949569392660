<template>
  <div class="loanrepay">
    <div class="callout error" v-if="alertMessageType == 'error'">
      <i class="fa fa-warning" aria-hidden="true"></i>

      <p>{{ alertMessage }}</p>
      <span class="close close-button" @click="clearAlertMessage">x</span>
    </div>
    <div class="callout primary" v-if="alertMessageType == 'warning'">
      <i class="fa fa-warning" aria-hidden="true"></i>

      <p>{{ alertMessage }}</p>
      <span class="close close-button" @click="clearAlertMessage">x</span>
    </div>
    <div class="callout success" v-if="alertMessageType == 'success'">
      <i class="fa fa-check-square-o" aria-hidden="true"></i>

      <p>{{ alertMessage }}</p>
      <span class="close close-button" @click="clearAlertMessage">x</span>
    </div>
    <div v-if="loan">
      <router-link class="btn btn-info" :to="`/dash/${loan.id}`"
        >Go back to loan</router-link
      >
    </div>
  </div>
</template>

<script>
import MerchantService from '../services/MerchantService';
export default {
  setup() {},
  name: 'Payment Complete',
  data() {
    return {
      loan: null,
      alertMessage: '',
      alertMessageType: '',
    };
  },
  methods: {
    clearAlertMessage() {
      this.alertMessage = '';
      this.alertMessageType = '';
    },
  },
  created() {
    var currentRequest = this.$store.getters.getCurrentLoan;
    this.loan = currentRequest;
    this.$store.dispatch('setCurrentBreadcrumb', 'Payment Complete');
    let data = {
      OrderMerchantReference: this.$route.query.OrderMerchantReference,
      OrderTrackingId: this.$route.query.OrderTrackingId,
    };
    MerchantService.processOnlinePayment(data)
      .then((response) => {
        console.log(response);
        this.alertMessage = response.message;
        this.alertMessageType = 'success';
      })
      .catch((error) => {
        console.log(error);
        this.alertMessage = error.message;
        this.alertMessageType = 'warning';
      });
  },
};
</script>

<style scoped>
.spacer {
  height: 50px;
}
.payment-option {
  height: 180px;
  width: 220px;
  left: 0px;
  top: 0px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #cccccc;
  border-bottom: medium none !important;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.payment-option.active {
  background: #eeeeee;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.payment-option-title {
  position: relative;
  margin: 0.5rem 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #000000;
}
.payment-option-line {
  height: 0px;
  left: 0px;
  top: 43px;
  border-color: #cccccc;
}
.settlement-logo {
  height: 32px !important;
  left: 86.24px !important;
  border-radius: 3px;
  position: relative;
}
.mpesa-logo {
  width: 60px !important;
  left: 63px !important;
  border-radius: 3px;
  position: relative;
}
.payment-card {
  height: 32px !important;
  width: 42.23px !important;
  left: 70px !important;
  border-radius: 3px;
  position: relative;
}
.payment-otion-desc {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0 1rem;
  color: #000000;
}
.controls {
  display: inline-block;
  margin-right: 20px;
}
.online-option {
  height: 60px !important;
  padding: 0.2rem 0;
}
.online-payment-option {
  display: inline;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 0.7rem 0.8rem;
  margin-right: 20px;
}
.phone-section {
  width: 800px;
  border: 1px solid #dadada;
}
.phone-section > div:first-child {
  background-color: #eeeeee;
  display: inline-block;
  padding: 5px;
  border: 1px solid #dadada;
  border-width: 0px 1px 1px 0px;
  margin-right: 10px;
}
.phone-section .input-group-field {
  width: auto;
  display: inline;
  height: 37px;
  margin-top: -5px;
  border-width: 0px 0.5px 0.5px 0px;
}
.phone-section .phone-icon {
  background-color: #eeeeee;
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  padding: 5px;
  border: 1px solid #dadada;
}
.phone-section i {
  font-size: 20px;
}
.area-code {
  width: 80px;
}
.control-label {
  min-width: 200px;
  text-align: right;
  padding-right: 5px;
}
.control-label,
.controls {
  display: inline-block;
}
.controls select {
  display: inline;
  width: auto;
  margin-right: 5px;
}
table {
  width: auto;
  border-width: 0px;
  border-collapse: unset;
}
table th:first-child {
  text-align: right;
}
th,
td {
  border-width: 0px;
  padding: 5px;
}
tr {
  background-color: white !important;
}
.dp__main {
  margin-bottom: 1rem;
}
.error {
  margin-top: -1.1rem;
  position: absolute;
}
iframe {
  border: none;
}
</style>
