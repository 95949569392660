<template>
  <footer>
    <div class="pp-copyright short">
      <div class="row">
        <div class="column medium-12">
          <div class="foot-links">
            <li>
              <a href="https://www.pesapal.com/security">Help & Contact</a>
            </li>
            <li>
              <a href="https://www.pesapal.com/home/termsandconditions">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href="https://www.pesapal.com/home/privacypolicy">
                Privacy Policy
              </a>
            </li>
            <li><a href="https://www.pesapal.com/security">Security</a></li>
          </div>
          ©2009-{{ year }} PesaPal™, All rights reserved.
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
