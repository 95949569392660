import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
var VueScrollTo = require('vue-scrollto');
const requireComponent = require.context(
  './components/inputs',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);
const app = createApp(App);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
  );
  app.component(componentName, componentConfig.default || componentConfig);
});
app.component('Datepicker', Datepicker);
app.use(store).use(router).use(VueScrollTo).mount('#app');
