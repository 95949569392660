<template>
  <div
    class="callout warning"
    v-if="$store.getters.isAuthenticated && !$store.getters.isActive"
  >
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    <p>Your account is blocked.</p>
  </div>
  <h2 class="pageTitle">Payments</h2>
  <table class="loanTable">
    <thead>
      <tr>
        <th class="sorting">Date</th>
        <th class="sorting">Amount</th>
        <th class="sorting">Payment method</th>
        <th class="sorting">Status</th>
        <th class="sorting">Loan ID</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="payment in payments" :key="payment.id">
        <td v-text="this.formatDates(payment.created_at)"></td>
        <td class="number">{{ payment.amount }}</td>
        <td class="number">{{ payment.payment_method }}</td>
        <td class="number" :class="getStatusClass(payment.payment_status)">
          {{ payment.payment_status }}
        </td>
        <td>
          <router-link :to="`/dash/${payment.reference}`">{{
            payment.reference || payment.reference
          }}</router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import MerchantService from '../services/MerchantService';
export default {
  name: 'Payments',
  data() {
    return {
      payments: [],
    };
  },
  methods: {
    formatDates(date) {
      date = new Date(date);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      const dateTimeFormat = new Intl.DateTimeFormat('en-us', options);
      return dateTimeFormat.format(date);
    },
    getStatusClass(status) {
      var alert = 'alert alert-';
      var statusClass = 'warning';
      if (status == 'COMPLETED') {
        statusClass = 'success';
      } else if (status == 'PENDING') {
        statusClass = 'info';
      } else if (status == 'FAILED') {
        statusClass = 'danger';
      }
      return alert + '' + statusClass;
    },
  },
  mounted() {
    if (this.$store.state.currentUser.merchant_details) {
      console.log(this.$store.state.currentUser.merchant_details);
      MerchantService.getPayments(
        this.$store.state.currentUser.merchant_details.customer.id
      ).then(({ data }) => {
        this.payments = data;
      });
    }
  },
  created() {
    this.$store.dispatch('setCurrentBreadcrumb', 'Payments');
  },
};
</script>
