<template>
  <header class="solid">
    <div class="title-bar hide-for-medium" data-responsive-toggle="pp-menu">
      <a href="/"><img src="/assets/images/logo.png" alt="Pesapal.com" /></a>
      <button id="simple-menu">
        &nbsp;
        <i class="pe-7s-menu2"></i>
        &nbsp;
      </button>
    </div>
    <div class="top-bar" id="pp-menu">
      <div id="sidr" class="top-bar-left">
        <ul class="menu">
          <li class="menu-text hide-for-small-only">
            <a href="/" id="logo">
              <img src="/assets/images/logo.png" alt="Pesapal.com" />
            </a>
          </li>
          <li class="country">
            <a
              class="cdrop"
              v-if="$store && $store.getters.getCountryCode == 'KE'"
            >
              <img
                src="https://www.pesapal.com/images/flags/KE.png"
                alt="Kenya"
              />
              <span>Kenya</span>
            </a>
            <a
              class="cdrop"
              v-if="$store && $store.getters.getCountryCode == 'UG'"
            >
              <img
                src="https://www.pesapal.com/images/flags/UG.png"
                alt="Uganda"
              />
              <span>Uganda</span>
            </a>
            <a
              class="cdrop"
              v-if="$store && $store.getters.getCountryCode == 'TZ'"
            >
              <img
                src="https://www.pesapal.com/images/flags/TZ.png"
                alt="Tanzania"
              />
              <span>Tanzania</span>
            </a>
          </li>
          <li class="menuitem home">
            <a href="https://www.pesapal.com">
              <i class="pe-7s-home"></i>
            </a>
          </li>
          <li id="businesslink" v-if="authenticated" class="current active">
            <a
              href="https://www.pesapal.com/dashboard/merchant/merchantdashboard"
            >
              Business
            </a>
          </li>
          <li id="personallink" v-if="authenticated">
            <a href="https://www.pesapal.com/dashboard/my/buyerdashboard">
              Personal
            </a>
          </li>
          <li
            id="businessdropdown"
            v-if="!authenticated"
            role="menuitem"
            class="is-dropdown-submenu-parent opens-right"
            aria-haspopup="true"
            aria-label="Business"
            data-is-click="false"
          >
            <a href="https://www.pesapal.com/ug/business">Business</a>
            <ul
              class="menu vertical submenu is-dropdown-submenu first-sub"
              data-submenu=""
              role="menu"
              style=""
            >
              <li
                id="item_1413_1"
                role="menuitem"
                class="show-for-small-only is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/business"
                  >Business Account</a
                >
              </li>
              <li
                id="item_1414"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/business/pos"
                  >In-Store Payments (POS)</a
                >
              </li>
              <li
                id="item_1413"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/business/online"
                  >Online payments</a
                >
              </li>
              <li
                id="item_14113"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/business/sector"
                  >Business Sectors</a
                >
              </li>

              <li
                id="item_1420"
                role="menuitem"
                class="menuitem is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/business/pricing"
                  >Pricing</a
                >
              </li>
            </ul>
          </li>

          <li
            id="personaldropdown"
            v-if="!authenticated"
            class="is-dropdown-submenu-parent opens-right"
            aria-haspopup="true"
            aria-label="Personal"
            data-is-click="false"
          >
            <a href="https://www.pesapal.com/ug/personal">Personal</a>
            <ul
              class="menu vertical submenu is-dropdown-submenu first-sub"
              data-submenu=""
              role="menu"
              style=""
            >
              <li
                id="item_1316_0"
                role="menuitem"
                class="show-for-small-only is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/personal"
                  >Personal Account</a
                >
              </li>
              <li
                id="item_1316_11"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/mobile">Pesapal Mobile</a>
              </li>
              <li
                id="item_1339"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/personal/buy-airtime"
                  >Buy Airtime</a
                >
              </li>
              <li
                id="item_1316_1_1"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/personal/pay-bills"
                  >Pay Bills</a
                >
              </li>
              <li
                id="item_1356"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/personal/event-tickets"
                  >Event tickets</a
                >
              </li>
              <li
                id="item_1409"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/personal/holiday-offers"
                  >Holiday &amp; Flights</a
                >
              </li>

              <li
                id="item_1348_2"
                role="menuitem"
                class="is-submenu-item is-dropdown-submenu-item"
              >
                <a href="https://www.pesapal.com/ug/directory">Where to Shop</a>
              </li>
            </ul>
          </li>

          <li id="bloglink" v-if="!authenticated">
            <a href="https://www.pesapal.com/blog">Blog</a>
          </li>
          <li id="helplink" v-if="!authenticated">
            <a href="https://www.pesapal.com/support">Help</a>
          </li>
        </ul>
      </div>
      <div class="top-bar-right">
        <ul class="menu">
          <li id="myaccountlink" v-if="authenticated">
            <a href="https://www.pesapal.com/dashboard/merchant/">My Account</a>
          </li>
          <li id="loginlink" v-else>
            <a href="https://www.pesapal.com/dashboard/account/signin">Login</a>
          </li>
          <li id="logofflink" v-if="authenticated">
            <router-link to="/" @click.prevent="logout"> Log Off </router-link>
          </li>
          <li id="registerlink" v-else>
            <a href="https://www.pesapal.com/dashboard/account/register"
              >Register</a
            >
          </li>
          <li class="sec">
            <a href="#" class="secimg s1">
              <span>
                <img
                  src="https://www.pesapal.com/images/norton.png"
                  alt="Norton"
                />
              </span>
            </a>
          </li>
          <li class="sec">
            <a href="#" class="secimg s2">
              <span>
                <img
                  src="https://www.pesapal.com/images/pcidss.png"
                  alt="PCI/DSS Compliant"
                />
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import AuthService from '../../services/AuthService';

export default {
  name: 'Header',
  computed: {
    authenticated() {
      return (
        this.$store &&
        this.$store.getters &&
        this.$store.getters.isAuthenticated
      );
    },
  },
  methods: {
    logout() {
      AuthService.logout().then(() => {
        this.$store.dispatch('logout');
        window.location.href = this.$store.getters.getRedirectUrl;
      });
    },
  },
};
</script>
