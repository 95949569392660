function formatNumber(number, currency = '') {
  if (currency) currency += ' ';
  if (number == 0) {
    return currency + '0';
  }
  return currency + new Intl.NumberFormat().format(number);
}

function formatDecimal(number, maximumFractionDigits = 2) {
  let formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits,
  });
  return formatter.format(number);
}

function formatDate(value) {
  return value.toLacalTimeString();
}

function setStatusClass(topic, status = null) {
  if (status === null) {
    status = topic.status;
  }
  switch (status) {
    case 0:
      topic.statusText = 'Placed';
      topic.statusClass = 'placed';
      break;
    case 1:
      topic.statusText = 'Pending';
      topic.statusClass = 'pending';
      break;
    case 2:
      topic.statusText = 'Approved';
      topic.statusClass = 'approved';
      break;
    case 3:
      topic.statusText = 'Active';
      topic.statusClass = 'active';
      break;
    case 4:
      topic.statusText = 'Rejected';
      topic.statusClass = 'rejected';
      break;
    case 5:
      topic.statusText = 'Closed';
      topic.statusClass = 'closed';
      break;
    case 10:
      topic.statusText = 'Foreclosing';
      topic.statusClass = 'foreclosure';
      break;
  }
}

function formatRequestsForDisplay(requests) {
  requests.forEach((request) => {
    request.amount = formatNumber(request.amount);
    if (request.loan && request.loan.statusKey) {
      request.status = request.loan.statusKey;
    }
    if (request.status < 3 || request.status == 4) {
      //Not yet in LMS, so we use the schedule on the request object
      request.interestAmount = formatNumber(request.schedule.total_interest);
      request.repaymentAmount = formatNumber(
        request.schedule.total_repayment_expected
      );
      request.balanceAmount = formatNumber(
        request.schedule.total_repayment_expected
      );
    } else {
      //Loan in LMS assumed, so we use the loan prop of the request object
      request.interestAmount = formatNumber(request.loan.interest_charged);
      request.repaymentAmount = formatNumber(request.loan.total_amount);
      request.amountPaid = formatNumber(request.loan.amount_paid);
      request.balanceAmount = formatNumber(request.loan.balance);
    }
    setStatusClass(request);
  });
}

export default {
  formatNumber,
  formatDecimal,
  setStatusClass,
  formatRequestsForDisplay,
  formatDate,
};
