<template>
  <label class="control-label" :for="uuid" v-if="label">{{ label }}</label>
  <div class="controls input-group">
    <input
      class="input-block-level input-large input-group-field"
      v-bind="$attrs"
      :placeholder="label"
      :value="modelValue"
      :id="uuid"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : null"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
  <p
    v-if="error"
    class="errorMessage"
    :id="`${uuid}-error`"
    aria-live="assertive"
  >
    {{ error }}
  </p>
</template>

<script>
import UniqueID from '../../features/UniqueIDs';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>
