<script>
/**
 * This component manages redirections: if user is authenticated, we'll redirect to the dashbaord, otherwise we redirect to the merchant
 * dashboard login
 */
import LoanService from '../services/LoanService';
import AuthService from '../services/AuthService';

export default {
  data() {},
  created() {
    var ppsid = null;
    var urlParts = window.location.href.split('ppsid=');
    if (urlParts.length > 1) {
      ppsid = decodeURIComponent(urlParts[1].split('#/')[0]);
    }
    AuthService.ssoLogin(ppsid).then((data) => {
      if (data.authenticated) {
        this.$store.dispatch('login', data);
        AuthService.me().then((data) => {
          this.$store.dispatch('setCurrentUser', data);
          this.redirect(data);
        });
      } else {
        window.location.href = data.redirectUrl;
      }
      this.$store.dispatch('setRedirectUrl', data.redirectUrl);
    });
  },
  methods: {
    redirect(currentUser) {
      if (currentUser && currentUser.merchant_details) {
        LoanService.getMerchantCurrentRequest(
          currentUser.merchant_details.external_id
        ).then(({ data }) => {
          if (data && data.length) {
            let loanStatus = data[data.length - 1].status;
            if (
              [4, 5].includes(loanStatus) ||
              (!this.$store.getters.isActive && loanStatus !== 3)
            ) {
              //If current loan is rejected or closed, show loan info page
              //or if merchant is blocked and loan is not active
              this.$router.push('/loaninfo');
            } else {
              this.$router.push('/dash');
            }
          } else {
            this.$router.push('/loaninfo');
          }
        });
      }
    },
  },
};
</script>
