<template>
  <div class="callout warning" v-if="errorMessage.length">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>

    <p>{{ errorMessage }}</p>
    <span class="close close-button" @click="clearErrors">x</span>
  </div>
  <fieldset class="form-horizontal">
    <div class="control-group">
      <label class="control-label">Name of Director:</label>
      <div class="controls">
        <input
          name="name"
          class="input-block-level"
          v-model="director.name"
          type="text"
        />
      </div>
    </div>
    <div class="control-group">
      <label class="control-label">ID Number:</label>
      <div class="controls">
        <input
          name="idNumber"
          class="input-block-level"
          v-model="director.id_number"
          type="text"
        />
      </div>
    </div>
    <div class="control-group">
      <label class="control-label">Designation:</label>
      <div class="controls">
        <input
          name="designation"
          class="input-block-level"
          v-model="director.designation"
          type="text"
        />
      </div>
    </div>
    <div class="control-group">
      <label class="control-label">Nature of Ownership:</label>
      <div class="controls">
        <input
          name="natureOfOwnership"
          class="input-block-level"
          v-model="director.nature_of_ownership"
          type="text"
        />
      </div>
    </div>
    <div class="control-group">
      <label class="control-label">Attach ID:</label>
      <div class="controls">
        <input type="file" ref="file" v-on:change="handleFileUpload" />
      </div>
    </div>
    <div class="controls">
      <input type="button" class="button" value="SAVE" @click="saveDirector" />
    </div>
    <div class="controls">
      <input
        type="button"
        class="button secondary"
        value="CANCEL"
        @click="redirectToProfile"
      />
    </div>
  </fieldset>
</template>

<script>
import AuthService from '../services/AuthService';
import FileService from '../services/FileService';
import MerchantService from '../services/MerchantService';

export default {
  name: 'DirectorForm',
  data() {
    return {
      file: null,
      errorMessage: '',
      director: {},
    };
  },
  created() {
    if (this.$store.getters.getCurrentDirector) {
      this.director = this.$store.getters.getCurrentDirector;
    }
    this.$store.dispatch(
      'setCurrentBreadcrumb',
      this.director.id ? 'Edit Director' : 'Add Dierctor'
    );
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    clearErrors() {
      this.errorMessage = '';
    },
    redirectToProfile() {
      this.clearErrors();
      this.$store.dispatch('setCurrentDirector', null);
      this.$router.push('/profile');
    },
    saveDirector() {
      if (this.validate()) {
        if (this.file) {
          let formData = new FormData();
          formData.append('file', this.file);
          formData.append('type', 'identity');
          FileService.upload(formData).then(({ file_path }) => {
            this.postDirector(file_path);
          });
        } else {
          this.postDirector(this.director.identity_document_url);
        }
      }
    },
    postDirector(file_path) {
      let payload = {
        name: this.director.name,
        id_number: this.director.id_number,
        designation: this.director.designation,
        nature_of_ownership: this.director.nature_of_ownership,
        identity_document_url: file_path,
        merchant_id: this.$store.state.currentUser.merchant_details.id,
      };
      let promise = this.director.id
        ? MerchantService.updateDirector(this.director.id, payload)
        : MerchantService.createDirector(payload);
      promise.then(() => {
        AuthService.me().then((data) => {
          this.$store.dispatch('setCurrentUser', data);
          this.redirectToProfile();
        });
      });
    },
    validate() {
      this.clearErrors();
      if (!this.director.name) {
        this.errorMessage = 'Director name is required';
        return;
      }
      if (!this.director.id_number) {
        this.errorMessage = 'Director ID number is required';
        return;
      }
      if (!this.director.designation) {
        this.errorMessage = 'Director designation is required';
        return;
      }
      if (!this.director.nature_of_ownership) {
        this.errorMessage = 'Director nature of ownership is required';
        return;
      }
      if (!this.file && !this.director.identity_document_url) {
        this.errorMessage = 'Director ID attachment is required';
        return;
      }
      return true;
    },
  },
};
</script>
