<template>
  <div
    class="pp-preloader"
    :style="
      $store.state.loading ? 'display: block; opacity: 50%;' : 'display: none;'
    "
  ></div>
  <Header />
  <div class="body-content pp-body dsh nobpadd">
    <div
      class="container pp-dashboard collapse row expanded"
      id="page-container"
    >
      <SideMenu />
      <div class="column medium-8 large-10" id="page-body">
        <div :class="$store.state.authenticated ? 'dright' : 'dright login'">
          <section
            v-if="$store.state.authenticated"
            class="pd-wrap pd-slim row expanded small-collapse large-uncollapse"
          >
            <div class="column large-11 medium-12">
              <nav
                aria-label="You are here:"
                role="navigation"
                class="column"
                id="breadcrumb"
              >
                <ul class="breadcrumbs">
                  <li>
                    <a
                      href="https://www.pesapal.com/dashboard/merchant/businesshome"
                    >
                      Business
                    </a>
                  </li>
                  <li><router-link to="/loaninfo">Loans</router-link></li>

                  <li v-if="$store.getters.getCurrentBreadcrumb">
                    <span class="show-for-sr">Current:</span>
                    {{ $store.getters.getCurrentBreadcrumb }}
                  </li>
                </ul>
              </nav>
            </div>
          </section>
          <section
            v-if="$store.state.authenticated"
            class="pd-wrap last row small-collapse large-uncollapse level-1"
          >
            <div class="column large-12 medium-12">
              <div class="column">
                <router-view />
              </div>
            </div>
          </section>
          <div v-else class="column large-8 medium-10 large-offset-1">
            <div class="column">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from './components/layouts/Header.vue';
import Footer from './components/layouts/Footer.vue';
import SideMenu from './components/layouts/SideMenu.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SideMenu,
  },
};
</script>
<style>
@import '../public/assets/css/template.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
