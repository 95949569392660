<template>
  <div class="loandash" v-if="$store.getters.isAuthenticated">
    <div class="callout alert" v-if="!$store.getters.isActive">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <p>
        Oops! Our system is taking a coffee break ☕. Hang tight, and we'll have
        things up and running smoothly again in no time!. Thanks for your
        patience!
      </p>
    </div>
    <div class="callout warning-blue" v-if="dueInDays < 10">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <p>
        Your loan installment is due in {{ dueInDays }} days.
        <a @click.prevent="repayLoan">Make Payment</a>
      </p>
    </div>
    <div class="row medium-uncollapse">
      <div class="column medium-6 pdleft">
        <LoanProgress
          v-if="currentRequest != null"
          :loanRequest="currentRequest"
        />
        <div class="spacer" v-if="currentRequest && currentRequest.status == 3">
          &nbsp;
        </div>
      </div>
      <div class="column medium-6 pdright">
        <LoanStatus
          v-if="currentRequest != null"
          :loanRequest="currentRequest"
        />
        <div
          class="control-group"
          v-if="currentRequest && currentRequest.status == 3"
        >
          <div class="controls">
            <input
              type="button"
              class="button"
              value="MAKE REPAYMENT"
              @click="repayLoan"
            />
            &nbsp;
            <input
              type="button"
              class="button success"
              value="PAY OFF LOAN"
              @click="payOffLoan"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row medium-uncollapse loan-detail" v-if="currentRequest">
      <div
        class="tabswrapper ui-tabs ui-corner-all ui-widget ui-widget-content"
      >
        <ul
          role="tablist"
          class="ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header"
        >
          <li
            v-if="currentRequest.loan"
            role="tab"
            tabindex="0"
            :class="statementClass"
            @click="showStatement"
          >
            <h4>Loan Statement</h4>
          </li>
          <li
            role="tab"
            tabindex="1"
            :class="scheduleClass"
            @click="showSchedule"
          >
            <h4>Repayment Schedule</h4>
          </li>
          <li
            role="tab"
            tabindex="2"
            v-if="
              loanDetails && loanDetails.charges && loanDetails.charges.length
            "
            :class="chargesClass"
            @click="showCharges"
          >
            <h4>Charges</h4>
          </li>
          <li
            role="tab"
            tabindex="3"
            v-if="repaymentRequests.length"
            :class="repaymentRequestsClass"
            @click="showRepaymentRequests"
          >
            <h4>Repayment Requests</h4>
          </li>
        </ul>
      </div>
      <p></p>
      <table v-if="activeTab == 'statement' && currentRequest.loan">
        <thead>
          <tr>
            <th class="sorting">Date</th>
            <th class="sorting">Transaction ID</th>
            <th class="sorting">Method</th>
            <th class="sorting">Amount Paid</th>
            <th class="sorting">Balance</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(transaction, index) in transactions" :key="index">
            <td>{{ transaction.transaction_date }}</td>
            <td>{{ transaction.transaction_id }}</td>
            <td>{{ transaction.payment_type }}</td>
            <td class="tred">{{ transaction.amount }}</td>
            <td>{{ transaction.balance }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="activeTab == 'schedule' && loanDetails == null">
        <thead>
          <tr>
            <th class="sorting">Installment Amount</th>
            <th class="sorting">Principal Due</th>
            <th class="sorting">Interest Due</th>
            <th class="sorting">Due Date</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(installment, index) in repaymentSchedule" :key="index">
            <td class="number">
              {{ installment.total_installment_for_period }}
            </td>
            <td class="number">{{ installment.principal_due }}</td>
            <td class="number">{{ installment.interest_due }}</td>
            <td>{{ installment.due_date }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="activeTab == 'schedule' && loanDetails">
        <thead>
          <tr>
            <th class="sorting">#</th>
            <th class="sorting">Due Date</th>
            <th class="sorting">Principle Due</th>
            <th class="sorting">Interest Due</th>
            <th class="sorting">Fees</th>
            <th class="sorting">Penalties</th>
            <th class="sorting">Paid</th>
            <th class="sorting">Outstanding</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{{ loanDetails.disbursement_date }}</td>
            <td></td>
            <td></td>
            <td class="number">{{ loanDetails.fees_charged }}</td>
            <td></td>
            <td class="number">{{ loanDetails.fees_paid_at_disbursement }}</td>
            <td></td>
          </tr>
          <tr v-for="(installment, index) in repaymentSchedule" :key="index">
            <td class="number">
              {{ index + 1 }}
            </td>
            <td>{{ installment.due_date }}</td>
            <td class="number">{{ installment.principal_due }}</td>
            <td class="number">{{ installment.interest_due }}</td>
            <td class="number">{{ installment.fees_due }}</td>
            <td class="number">{{ installment.penalty_due }}</td>
            <td class="number">{{ installment.total_paid_for_period }}</td>
            <td class="number">
              {{ installment.total_outstanding_for_period }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td>Total</td>
            <td class="number">{{ loanDetails.totalPrincipal }}</td>
            <td class="number">{{ loanDetails.totalInterest }}</td>
            <td class="number">{{ loanDetails.totalFees }}</td>
            <td class="number">{{ loanDetails.totalPenalties }}</td>
            <td class="number">{{ loanDetails.totalPaid }}</td>
            <td class="number">{{ loanDetails.totalOutstanding }}</td>
          </tr>
        </tfoot>
      </table>
      <table
        v-if="
          activeTab == 'charges' &&
          loanDetails.charges &&
          loanDetails.charges.length
        "
      >
        <thead>
          <tr>
            <th class="sorting">Name</th>
            <th class="sorting">Charge Type</th>
            <th class="sorting">Fee/Penalty</th>
            <th class="sorting">Due Date</th>
            <th class="sorting">Amount</th>
            <th class="sorting">Amount Paid</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(charge, index) in loanDetails.charges" :key="index">
            <td>{{ charge.name }}</td>
            <td>{{ charge.charge_time }}</td>
            <td>{{ charge.penalty ? 'Penalty' : 'Fee' }}</td>
            <td>{{ charge.due_date }}</td>
            <td class="tred number">{{ charge.amount }}</td>
            <td class="number">{{ charge.amountPaid }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="activeTab == 'repaymentRequests'">
        <thead>
          <tr>
            <th class="sorting">Payment Date</th>
            <th class="sorting">Amount</th>
            <th class="sorting">Payment Method</th>
            <th class="sorting">Reference</th>
            <th class="sorting">Request Date</th>
            <th class="sorting">Is Pay-off?</th>
            <th class="sorting">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(request, index) in repaymentRequests" :key="index">
            <td>{{ request.paymentDate }}</td>
            <td class="number">{{ request.amount }}</td>
            <td>{{ request.payment_method }}</td>
            <td>{{ request.reference }}</td>
            <td>{{ request.dateCreated }}</td>
            <td>{{ request.is_payoff == 1 ? 'Yes' : 'No' }}</td>
            <td :class="request.statusClass">
              {{ request.statusDescription }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Util from '../util/Util';
import LoanService from '../services/LoanService';
import LoanStatus from '../components/loans/LoanStatus.vue';
import LoanProgress from '../components/loans/LoanProgress.vue';

export default {
  name: 'LoanDash',
  props: {
    requestId: {
      type: Number,
    },
  },
  components: {
    LoanProgress,
    LoanStatus,
  },
  data() {
    return {
      dueInDays: 10,
      loanDetails: null, //Will be null unless loan is active
      currentRequest: null,
      transactions: [],
      repaymentSchedule: [],
      repaymentRequests: [],
      activeTab: 'statement',
      baseTabClass: 'ui-tabs-tab ui-corner-top ui-state-default ui-tab',
    };
  },
  computed: {
    statementClass() {
      return this.activeTab === 'statement'
        ? this.baseTabClass + ' ui-tabs-active ui-state-active'
        : this.baseTabClass;
    },
    scheduleClass() {
      return this.activeTab === 'schedule'
        ? this.baseTabClass + ' ui-tabs-active ui-state-active'
        : this.baseTabClass;
    },
    chargesClass() {
      return this.activeTab === 'charges'
        ? this.baseTabClass + ' ui-tabs-active ui-state-active'
        : this.baseTabClass;
    },
    repaymentRequestsClass() {
      return this.activeTab === 'repaymentRequests'
        ? this.baseTabClass + ' ui-tabs-active ui-state-active'
        : this.baseTabClass;
    },
  },
  created() {
    this.$store.dispatch('loading', false);
    this.$store.dispatch('setCurrentBreadcrumb', null);
    if (this.requestId) {
      LoanService.getLoanRequestById(this.requestId).then(({ data }) => {
        this.extractCurrentRequest([data]);
      });
    } else {
      if (this.$store.state.currentUser.merchant_details) {
        LoanService.getMerchantCurrentRequest(
          this.$store.state.currentUser.merchant_details.external_id
        ).then(({ data }) => {
          this.extractCurrentRequest(data);
        });
      }
    }
  },
  methods: {
    extractCurrentRequest(requests) {
      if (requests && requests.length) {
        this.currentRequest = requests[requests.length - 1]; //latest request only
        Util.formatRequestsForDisplay([this.currentRequest]);
        if (this.currentRequest.loan) {
          if (Array.isArray(this.currentRequest.loan)) {
            //We are expecting an object, if we get an array then most likely it's empty and the loan request was rejected
            delete this.currentRequest.loan; //delete this field because many decisions depend on it
            this.currentRequest.amountPaid = 0;
            this.repaymentSchedule =
              this.currentRequest.schedule.repayment_schedule;
            this.repaymentSchedule.forEach((x) => {
              x.interest_due = Util.formatNumber(x.interest_due);
              x.principal_due = Util.formatNumber(x.principal_outstanding);
              x.total_installment_for_period = Util.formatNumber(
                x.total_outstanding
              );
            });
            this.showSchedule();
            this.$store.dispatch(
              'setCurrentBreadcrumb',
              `#${this.currentRequest.id}`
            );
          } else {
            this.extractActiveLoanDetails(this.currentRequest.loan);
            this.$store.dispatch(
              'setCurrentBreadcrumb',
              `#${this.currentRequest.loan.id}`
            );
          }
          if (
            this.currentRequest.loan &&
            this.currentRequest.loan.status == 10
          ) {
            this.currentRequest.status = 10;
          }
        }
      } else {
        this.$router.push('/loaninfo');
      }
    },
    extractActiveLoanDetails(loan) {
      LoanService.getLoanDetails(loan.loan_id).then(({ data }) => {
        let balance =
          data.principal_disbursed +
          data.interest_charged +
          data.fees_charged -
          data.fees_paid_at_disbursement +
          data.penalties_charged;
        this.transactions = data.transactions;
        this.loanDetails = data;
        if (this.transactions) {
          this.transactions.forEach((x) => {
            balance -= x.amount;
            x.balance = Util.formatNumber(balance);
            x.amount = Util.formatNumber(x.amount);
          });
        }
        if (this.loanDetails.charges && this.loanDetails.charges.length) {
          this.loanDetails.charges.forEach((x) => {
            x.amount = Util.formatNumber(x.amount);
            x.amountPaid = Util.formatNumber(x.amountPaid);
          });
        }
        this.repaymentSchedule = data.repayment_schedule;
        this.loanDetails.totalPrincipal = 0;
        this.loanDetails.totalInterest = 0;
        this.loanDetails.totalFees = 0;
        this.loanDetails.totalPenalties = 0;
        this.loanDetails.totalPaid = this.loanDetails.fees_paid_at_disbursement;
        this.loanDetails.totalOutstanding = 0;
        this.repaymentSchedule = data.repayment_schedule;
        this.repaymentSchedule.forEach((x) => {
          this.loanDetails.totalPrincipal += x.principal_due;
          this.loanDetails.totalInterest += x.interest_due;
          this.loanDetails.totalFees += x.fees_due;
          this.loanDetails.totalPenalties += x.penalty_due;
          this.loanDetails.totalPaid += x.total_paid_for_period;
          this.loanDetails.totalOutstanding += x.total_outstanding_for_period;

          x.total_outstanding_for_period = Util.formatNumber(
            x.total_outstanding_for_period
          );
          x.principal_due = Util.formatNumber(x.principal_due);
          x.interest_due = Util.formatNumber(x.interest_due);
          x.fees_due = Util.formatNumber(x.fees_due);
          x.penalty_due = Util.formatNumber(x.penalty_due);
          x.total_paid_for_period = Util.formatNumber(x.total_paid_for_period);
        });
        this.loanDetails.totalPrincipal = Util.formatNumber(
          this.loanDetails.totalPrincipal
        );
        this.loanDetails.totalInterest = Util.formatNumber(
          this.loanDetails.totalInterest
        );
        this.loanDetails.totalFees = Util.formatNumber(
          this.loanDetails.totalFees
        );
        this.loanDetails.totalPenalties = Util.formatNumber(
          this.loanDetails.totalPenalties
        );
        this.loanDetails.totalPaid = Util.formatNumber(
          this.loanDetails.totalPaid
        );
        this.loanDetails.totalOutstanding = Util.formatNumber(
          this.loanDetails.totalOutstanding
        );

        this.loanDetails.amountPaid = Util.formatNumber(
          this.loanDetails.amount_paid
        );
        this.loanDetails.balance = Util.formatNumber(
          this.loanDetails.total_outstanding_balance
        );
        if (this.repaymentSchedule.filter((x) => !x.complete).length) {
          var nextInstallment = this.repaymentSchedule.filter(
            (x) => !x.complete
          )[0];
          if (nextInstallment) {
            this.currentRequest.dueDate = nextInstallment.due_date;
          }
        }
        this.dueInDays = data.daysToDueDate;
        this.currentRequest.repaymentSchedule = this.repaymentSchedule; //This is the updated schedule from LMS
      });
      LoanService.getLoanRepaymentRequests(loan.id).then(({ data }) => {
        this.repaymentRequests = data;
        this.repaymentRequests.forEach((x) => {
          x.amount = Util.formatNumber(x.amount);
          Util.setStatusClass(x);
        });
      });
    },
    repayLoan() {
      this.$store.dispatch('setCurrentLoan', this.currentRequest);
      this.$router.push('/repayloan');
    },
    payOffLoan() {
      this.$store.dispatch('setCurrentLoan', this.currentRequest);
      this.$router.push('/payoffloan');
    },
    showStatement() {
      this.activeTab = 'statement';
    },
    showSchedule() {
      this.activeTab = 'schedule';
    },
    showCharges() {
      this.activeTab = 'charges';
    },
    showRepaymentRequests() {
      this.activeTab = 'repaymentRequests';
    },
  },
};
</script>

<style scoped>
.spacer {
  height: 200px;
}
</style>
