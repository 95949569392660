import request from '@/util/request';

function upload(data) {
  return request({
    url: 'api/files/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'post',
    data,
  });
}

export default {
  upload,
};
