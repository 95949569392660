import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Redirect',
    component: () => import('../views/Redirect.vue'),
    //component: () => import('../views/Login.vue'),
  },
  {
    path: '/dash',
    name: 'Dash',
    component: () => import('../views/LoanDash.vue'),
  },
  {
    path: '/dash/:requestId',
    name: 'DashByRequestId',
    props: true,
    component: () => import('../views/LoanDash.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/payments',
    name: 'Payments',
    component: () => import('../views/Payments.vue'),
  },
  {
    path: '/reset_password',
    name: 'PasswordReset',
    component: () => import('../views/PasswordReset.vue'),
  },
  {
    path: '/apply',
    name: 'LoanApplication',
    component: () => import('../views/LoanApplication.vue'),
  },
  {
    path: '/history',
    name: 'LoanHistory',
    component: () => import('../views/LoanHistory.vue'),
  },
  {
    path: '/profile',
    name: 'LoanProfile',
    component: () => import('../views/LoanProfile.vue'),
  },
  {
    path: '/director',
    name: 'Director',
    component: () => import('../views/DirectorForm.vue'),
  },
  {
    path: '/loaninfo',
    name: 'LoanInfo',
    component: () => import('../views/LoanInfo.vue'),
  },
  {
    path: '/repayloan',
    name: 'RepayLoan',
    component: () => import('../views/RepayLoan.vue'),
  },
  {
    path: '/payoffloan',
    name: 'PayOffLoan',
    component: () => import('../views/PayOffLoan.vue'),
  },
  {
    path: '/payment_complete',
    name: 'paymentComplete',
    component: () => import('../views/PaymentComplete.vue'),
  },
  {
    path: '/calculator',
    name: 'LoanCalculator',
    component: () => import('../views/LoanCalculator.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (
    to.name !== 'Redirect' &&
    to.name !== 'PasswordReset' &&
    to.name !== 'Login'
  ) {
    if (store.state.authenticated) {
      next();
    } else {
      next({ name: 'Redirect' });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
