<template>
  <div class="loaninfo">
    <div
      class="callout warning"
      v-if="$store.getters.isAuthenticated && !$store.getters.isActive"
    >
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      <p>Your account is blocked.</p>
    </div>
    <div class="row medium-uncollapse">
      <div class="column medium-6 pdleft">
        <h3>Loans</h3>
        <p>
          This is a short-term credit facility designed to finance Pesapal
          merchants for working capital.
        </p>
        <h3>How it works</h3>
        <ol class="number-list">
          <li>Maximum repayment period of 6months</li>
          <li>Interest rate of 3% p.m. on reducing balance</li>
          <li>
            Loan application and evaluation fee of 1.5% because we charge on all
            borrowings.
          </li>
          <li>
            Loan amounts depend on the transaction’s history merchant does with
            Pesapal
          </li>
          <li>
            Disbursement done within 24 hrs. after application and all required
            documents submitted
          </li>
          <li>
            Late payment fee: 1% of expected installment above the normal
            interest rates on the amount outstanding
          </li>
        </ol>
        <h3>Qualifications</h3>
        <ul class="check-list">
          <li>Must be a Pesapal merchant for a minimum of 6 months</li>
          <li>
            Must demonstrate ability to repay from the transactions merchant
            does through pesapal
          </li>
          <li>The purpose of the funds requested must be clear</li>
          <li>
            A board resolution document is required the first time you apply for
            a loan.
          </li>
        </ul>
        <router-link
          v-if="$store.getters.isActive && $store.getters.getCreditLimit"
          to="/apply"
          class="button alert"
          >APPLY NOW</router-link
        >
      </div>
      <div class="column medium-6 pdright">
        <h3>Pesapal Loan</h3>
        <div v-if="$store.getters.getCreditLimit">
          <p>You qualify for a loan of</p>
          <h3>{{ $store.getters.getCurrency }} {{ creditLimit }}</h3>
          <router-link
            to="/apply"
            class="button alert"
            v-if="$store.getters.isActive"
            >APPLY NOW</router-link
          >
          <br />
          <router-link to="/calculator" class="button primary"
            >LOAN CALCULATOR</router-link
          >
        </div>
        <div v-else>
          <p>
            Keep transacting on your account. <br />You could qualify for a loan
            of
          </p>
          <h3>{{ $store.getters.getCurrency }} 100,000.00</h3>
          <br />
          <a
            href="https://www.pesapal.com/dashboard/merchant/merchantdashboard"
            class="button primary"
            >BACK TO DASHBOARD</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from '../util/Util';
export default {
  name: 'LoanInfo',
  computed: {
    creditLimit() {
      if (this.$store.getters.getCreditLimit) {
        return Util.formatNumber(this.$store.getters.getCreditLimit);
      }
      return '';
    },
  },
  created() {
    this.$store.dispatch('setCurrentBreadcrumb', 'Loan Info');
  },
};
</script>
