import request from '@/util/request';

function createMerchant(data) {
  return request({
    url: '/api/merchant/create',
    method: 'post',
    data: data,
  });
}

function createDirector(data) {
  return request({
    url: '/api/merchant/directors',
    method: 'post',
    data,
  });
}

function updateDirector(id, data) {
  return request({
    url: `/api/merchant/directors/${id}`,
    method: 'put',
    data,
  });
}

function deleteDirector(id) {
  return request({
    url: `/api/merchant/directors/${id}`,
    method: 'delete',
  });
}

function makePayment(data) {
  return request({
    url: '/api/merchant/payments/make_online_repayment',
    method: 'post',
    data,
  });
}

function processOnlinePayment(data) {
  return request({
    url: '/api/merchant/payments/process_online_repayment',
    method: 'post',
    data,
  });
}

function getPayments(customerId) {
  return request({
    url: '/api/merchant/payments/' + customerId,
    method: 'get',
  });
}

export default {
  createMerchant,
  createDirector,
  updateDirector,
  deleteDirector,
  makePayment,
  processOnlinePayment,
  getPayments,
};
