<template>
  <div class="controls input-group">
    <input
      class="input-block-level input-large input-group-field"
      type="radio"
      :id="uuid"
      v-bind="$attrs"
      :checked="modelValue === value"
      :value="value"
      @change="$emit('update:modelValue', value)"
    />
    <label class="control-label" :for="uuid" v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import UniqueID from '../../features/UniqueIDs';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const uuid = UniqueID().getID();
    return {
      uuid,
    };
  },
};
</script>
