<template>
  <div id="main" class="boxgrad pp-loginblock">
    <div
      id="pp-main"
      class="tabs-panel is-active"
      aria-hidden="false"
      v-if="!$store.state.authenticated"
    >
      <div class="row">
        <div class="column medium-7 lleft">
          <fieldset>
            <h1>Login to your account</h1>
            <div
              v-if="loginfailed"
              class="validation-summary-errors"
              data-valmsg-summary="true"
            >
              <ul>
                <li>No user with this Username and Password found</li>
              </ul>
            </div>
            <form @submit.prevent="login">
              <div class="form-group">
                <label for="username"
                  >Email / Username
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="UserName"
                    data-valmsg-replace="false"
                    >*</span
                  ></label
                >
                <div class="controls input-group">
                  <span class="input-group-label"
                    ><i class="pe-7s-mail"></i
                  ></span>
                  <input
                    class="input-block-level input-large input-group-field"
                    data-val="true"
                    data-val-required="The User name field is required."
                    id="UserName"
                    name="UserName"
                    placeholder="Email or Username"
                    type="text"
                    v-model="username"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="password">
                  Password
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="Password"
                    data-valmsg-replace="false"
                    >*</span
                  >
                </label>
                <div class="controls input-group">
                  <span class="input-group-label"
                    ><i class="pe-7s-lock"></i
                  ></span>
                  <input
                    class="input-block-level input-large input-group-field"
                    data-val="true"
                    data-val-required="The Password field is required."
                    id="Password"
                    name="Password"
                    type="password"
                    v-model="password"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="controls input-group">
                  <label class="checkbox">
                    <input
                      class="remember"
                      data-val="true"
                      data-val-required="The Remember me? field is required."
                      id="RememberMe"
                      name="RememberMe"
                      type="checkbox"
                      value="true"
                    /><input name="RememberMe" type="hidden" value="false" />
                    Remember me?
                  </label>
                </div>
              </div>

              <div class="form-group form-buttons">
                <div class="controls">
                  <input
                    type="submit"
                    name="submitButton"
                    value="Log In"
                    class="button alert"
                  />
                  <span v-if="loginfailed" class="loginfailed"
                    >Invalid Credentials</span
                  >
                  <a
                    href="/dashboard/account/register"
                    class="button hollow plain float-right"
                    >Create new Account</a
                  >
                  <p>
                    <a
                      class="styledlink"
                      href="/dashboard/account/forgotpassword"
                      >Don't know your password?</a
                    >
                  </p>

                  <!--LOGIN_PAGE_INDENTIFIER-->
                  <input
                    id="Identifier"
                    name="Identifier"
                    type="hidden"
                    value="LOGIN_PAGE_INDENTIFIER"
                  />
                </div>
              </div>
            </form>
          </fieldset>
        </div>
        <div class="column medium-5 lright">
          <h4>Keep your account Secure</h4>
          <ul class="hints">
            <li>
              <i class="pe-7s-lock"></i>
              <h5>Password Safety</h5>
              Do not share your password or have it stored on a browser by
              default unless necessary.
            </li>
            <li>
              <i class="pe-7s-power"></i>
              <h5>Always Logout</h5>
              Once done, always logout so that no one gains access to your
              account without your knowledge.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';
import LoanService from '../services/LoanService';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      loginfailed: false,
    };
  },
  created() {
    if (
      this.$store &&
      this.$store.getters &&
      this.$store.getters.isAuthenticated
    ) {
      this.redirect(this.$store.state.currentUser);
    }
    this.$store.dispatch('loading', false);
  },
  methods: {
    login() {
      this.loginfailed = false;
      if (this.username && this.password) {
        AuthService.auth({
          username: this.username,
          password: this.password,
        })
          .then((data) => {
            this.$store.dispatch('login', data);
            AuthService.me().then((data) => {
              this.$store.dispatch('setCurrentUser', data);
              this.redirect(data);
            });
          })
          .catch(({ response }) => {
            if (response && response.status === 401) {
              //Login failed -- invalid credentials
              this.loginfailed = true;
            }
          });
      }
    },
    redirect(currentUser) {
      if (currentUser && currentUser.merchant_details) {
        LoanService.getMerchantCurrentRequest(
          currentUser.merchant_details.external_id
        ).then(({ data }) => {
          if (data && data.length) {
            let loanStatus = data[data.length - 1].status;
            if (
              [4, 5].includes(loanStatus) ||
              (!this.$store.getters.isActive && loanStatus !== 3)
            ) {
              //If current loan is rejected or closed, show loan info page
              //or if merchant is blocked and loan is not active
              this.$router.push('/loaninfo');
            } else {
              this.$router.push('/dash');
            }
          } else {
            this.$router.push('/loaninfo');
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.validation-summary-errors {
  text-align: center;
}
</style>
