<template>
  <div id="main" class="boxgrad pp-loginblock">
    <div id="pp-main" class="tabs-panel is-active" aria-hidden="false">
      <div class="row">
        <div class="column medium-7 lleft">
          <fieldset>
            <h1>Set your loan account password</h1>
            <div
              v-if="passwordResetFailed"
              class="validation-summary-errors"
              data-valmsg-summary="true"
            >
              <ul>
                <li>{{ errorMessage }}</li>
              </ul>
            </div>
            <div
              v-if="passwordResetSuccess"
              class="alert alert-success"
              data-valmsg-summary="true"
            >
              <ul>
                <li>{{ message }}</li>
              </ul>
            </div>
            <form @submit.prevent="resetPassword">
              <div class="form-group">
                <label for="email"
                  >Email
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="email"
                    data-valmsg-replace="false"
                    >*</span
                  ></label
                >
                <div class="controls input-group">
                  <span class="input-group-label"
                    ><i class="pe-7s-mail"></i
                  ></span>
                  <input
                    class="input-block-level input-large input-group-field"
                    data-val="true"
                    data-val-required="The User email field is required."
                    id="email"
                    name="email"
                    placeholder="Email "
                    type="text"
                    v-model="email"
                  />
                </div>
              </div>
              <div class="form-group">
                <label for="password">
                  Password
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="Password"
                    data-valmsg-replace="false"
                    >*</span
                  >
                </label>
                <div class="controls input-group">
                  <span class="input-group-label"
                    ><i class="pe-7s-lock"></i
                  ></span>
                  <input
                    class="input-block-level input-large input-group-field"
                    data-val="true"
                    data-val-required="The Password field is required."
                    id="Password"
                    name="Password"
                    type="password"
                    v-model="password"
                  />
                </div>
              </div>

              <div class="form-group">
                <label for="password">
                  Confirm Password
                  <span
                    class="field-validation-valid"
                    data-valmsg-for="Password"
                    data-valmsg-replace="false"
                    >*</span
                  >
                </label>
                <div class="controls input-group">
                  <span class="input-group-label"
                    ><i class="pe-7s-lock"></i
                  ></span>
                  <input
                    class="input-block-level input-large input-group-field"
                    data-val="true"
                    data-val-required="The Password confirmation field is required."
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    v-model="confirmPassword"
                  />
                </div>
              </div>

              <div class="form-group form-buttons">
                <div class="controls">
                  <input
                    type="submit"
                    name="submitButton"
                    value="Submit"
                    class="button alert"
                  />

                  <!--LOGIN_PAGE_INDENTIFIER-->
                  <input
                    id="Identifier"
                    name="Identifier"
                    type="hidden"
                    value="LOGIN_PAGE_INDENTIFIER"
                  />
                </div>
              </div>
            </form>
          </fieldset>
        </div>
        <div class="column medium-5 lright">
          <h4>Keep your account Secure</h4>
          <ul class="hints">
            <li>
              <i class="pe-7s-lock"></i>
              <h5>Password Safety</h5>
              Do not share your password or have it stored on a browser by
              default unless necessary.
            </li>
            <li>
              <i class="pe-7s-power"></i>
              <h5>Always Logout</h5>
              Once done, always logout so that no one gains access to your
              account without your knowledge.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '../services/AuthService';
import LoanService from '../services/LoanService';

export default {
  name: 'Password reset',
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      passwordResetToken: '',
      passwordResetFailed: false,
      passwordResetSuccess: false,
      errorMessage: '',
      message: '',
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  created() {
    this.$store.dispatch('setCurrentBreadcrumb', 'Reset Password');
  },
  methods: {
    resetPassword() {
      this.passwordResetToken = this.$route.query.token;
      this.passwordResetFailed = false;
      if (
        this.email &&
        this.password &&
        this.confirmPassword &&
        this.passwordResetToken
      ) {
        AuthService.resetPassword({
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
          token: this.passwordResetToken,
        })
          .then((data) => {
            console.log(data);
            if (data.status == 200) {
              this.passwordResetSuccess = true;
              this.passwordResetFailed = false;
              this.message = data.message;
              this.$router.push('/login');
            } else {
              this.passwordResetFailed = true;
              this.errorMessage = data.message;
            }
          })
          .catch(({ response }) => {
            console.log(response);
            if (response && response.status === 400) {
              this.passwordResetFailed = true;
              this.errorMessage = response.data.message;
            }
          });
      }
    },
    redirect(currentUser) {
      if (currentUser && currentUser.merchant_details) {
        LoanService.getMerchantCurrentRequest(
          currentUser.merchant_details.external_id
        ).then(({ data }) => {
          if (data && data.length) {
            let loanStatus = data[data.length - 1].status;
            if (
              [4, 5].includes(loanStatus) ||
              (!this.$store.getters.isActive && loanStatus !== 3)
            ) {
              //If current loan is rejected or closed, show loan info page
              //or if merchant is blocked and loan is not active
              this.$router.push('/loaninfo');
            } else {
              this.$router.push('/dash');
            }
          } else {
            this.$router.push('/loaninfo');
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.validation-summary-errors {
  text-align: center;
}
</style>
