import request from '@/util/request';

function auth(data) {
  return request({
    url: '/api/merchant/login',
    method: 'post',
    data: data,
  });
}

function me() {
  return request({
    url: '/api/merchant/profile',
    method: 'get',
  });
}

function logout() {
  return request({
    url: '/api/merchant/logout',
    method: 'post',
  });
}

function ssoLogin(ppSid) {
  return request({
    url: `/api/merchant/ssologin`,
    method: 'post',
    data: { ppsid: ppSid },
  });
}

function resetPassword(data) {
  return request({
    url: `/api/merchant/set_password`,
    method: 'post',
    data: data,
  });
}

export default {
  auth,
  me,
  logout,
  ssoLogin,
  resetPassword,
};
