import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  key: 'vma',
  storage: window.localStorage,
});

export default createStore({
  state: {
    loading: false,
    authenticated: false,
    currentUser: {},
    countryCode: 'KE',
    currency: 'KES',
    loanAmount: null,
    currentLoan: null,
    currentDirector: null,
    currentBreadCrumb: null,
    redirectUrl: null,
  },
  mutations: {
    SET_LOGIN(state, { access_token, expires_in }) {
      state.access_token = access_token;
      state.expires_in = expires_in;
      state.authenticated = access_token !== undefined;
    },
    SET_CURRENT_USER(state, currentUser) {
      state.currentUser = currentUser || {};
      if (currentUser.merchant_details) {
        state.currency = currentUser.merchant_details.currency;
        state.countryCode = currentUser.merchant_details.country_code;
      }
    },
    SET_LOGOUT(state) {
      delete state.expires_in;
      delete state.access_token;
      state.currentUser = {};
      state.authenticated = false;
    },
    SET_REDIRECT_URL(state, redirectUrl) {
      state.redirectUrl = redirectUrl;
    },
    SET_CURRENT_LOAN(state, currentLoan) {
      state.currentLoan = currentLoan || null;
    },
    SET_CURRENT_DIRECTOR(state, currentDirector) {
      state.currentDirector = currentDirector || null;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_CURRENT_BREADCRUMB(state, currentBreadCrumb) {
      state.currentBreadCrumb = currentBreadCrumb;
    },
    SET_LOAN_AMOUNT(state, loanAmount) {
      state.loanAmount = loanAmount;
    },
  },
  actions: {
    login({ commit }, value) {
      commit('SET_LOGIN', value);
    },
    setCurrentUser({ commit }, value) {
      commit('SET_CURRENT_USER', value);
    },
    logout({ commit }) {
      commit('SET_LOGOUT');
    },
    setRedirectUrl({ commit }, value) {
      commit('SET_REDIRECT_URL', value);
    },
    setCurrentLoan({ commit }, value) {
      commit('SET_CURRENT_LOAN', value);
    },
    setCurrentDirector({ commit }, value) {
      commit('SET_CURRENT_DIRECTOR', value);
    },
    loading({ commit }, value) {
      commit('SET_LOADING', value);
    },
    setCurrentBreadcrumb({ commit }, value) {
      commit('SET_CURRENT_BREADCRUMB', value);
    },
    setLoanAmount({ commit }, value) {
      commit('SET_LOAN_AMOUNT', value);
    },
  },
  modules: {},
  getters: {
    isAuthenticated: (state) => {
      return state.authenticated;
    },
    getAccessToken: (state) => {
      return state.access_token;
    },
    getUserName: (state) => {
      if (state.currentUser && state.currentUser.name) {
        return state.currentUser.name;
      }
      return '';
    },
    getMerchantName: (state) => {
      if (state.currentUser && state.currentUser.merchant_details) {
        return state.currentUser.merchant_details.merchant_name;
      }
      return '';
    },
    getCurrency: (state) => {
      return state.currency;
    },
    getCountryCode: (state) => {
      return state.countryCode;
    },
    getCreditLimit: (state) => {
      if (
        state.currentUser &&
        state.currentUser.score &&
        state.currentUser.score.creditLimit
      ) {
        return state.currentUser.score.creditLimit;
      }
      return null;
    },
    getMaxLoanTenure: (state) => {
      if (
        state.currentUser &&
        state.currentUser.score &&
        state.currentUser.score.maxLoanTenureMonths
      ) {
        return state.currentUser.score.maxLoanTenureMonths;
      }
      return null;
    },
    getDirectors: (state) => {
      if (
        state.currentUser &&
        state.currentUser.merchant_details &&
        state.currentUser.merchant_details.directors
      ) {
        return state.currentUser.merchant_details.directors;
      }
      return [];
    },
    getCurrentLoan: (state) => {
      return state.currentLoan;
    },
    getCurrentDirector: (state) => {
      return state.currentDirector;
    },
    getCurrentBreadcrumb: (state) => {
      return state.currentBreadCrumb;
    },
    getCurrentUser: (state) => {
      return state.currentUser;
    },
    getLoanAmount: (state) => {
      return state.loanAmount;
    },
    getBoardResolutionPath: (state) => {
      if (state.currentUser && state.currentUser.merchant_details) {
        return state.currentUser.merchant_details.board_resolution_path;
      }
      return null;
    },
    isActive: (state) => {
      return (
        state.currentUser &&
        state.currentUser.merchant_details &&
        state.currentUser.merchant_details.customer &&
        state.currentUser.merchant_details.customer.status === 1
      );
    },
    getRedirectUrl: (state) => {
      return state.redirectUrl;
    },
  },
  plugins: [vuexLocal.plugin],
});
