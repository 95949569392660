<template>
  <h2 class="pageTitle">Loans</h2>
  <h4>Repayment Progress</h4>
  <div
    class="progress"
    role="progressbar"
    :aria-valuenow="percentage"
    aria-valuemin="0"
    :aria-valuetext="percentage + ' percent'"
    aria-valuemax="100"
  >
    <div
      class="progress-meter"
      :style="`width: ${percentage}%; color: ${
        percentage > 0 ? '#fff' : '#1a1a1a;'
      }; font-weight: 700; padding-left: 
        ${percentage / 2 - 2}%`"
    >
      &nbsp;{{ percentage }}%
    </div>
  </div>
  <span class="duedate" v-if="loanRequest.dueDate">
    Due on <b>{{ loanRequest.dueDate }}</b>
  </span>
  <span :class="statusClass">{{ statusText }}</span>
</template>

<script>
import Util from '../../util/Util';

export default {
  name: 'LoanProgress',
  data() {
    return {
      percentage: 0,
      statusClass: 'pending',
      statusText: 'Loan Placed',
    };
  },
  props: {
    loanRequest: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    let status = this.loanRequest.status;
    if (this.loanRequest.loan) {
      this.percentage =
        (this.loanRequest.loan.amount_paid /
          this.loanRequest.loan.total_amount) *
        100;
      this.percentage = Util.formatDecimal(this.percentage, 0);
    } else {
      this.percentage = 0;
    }
    Util.setStatusClass(this, status);
    if (status === 3) {
      this.statusClass = 'active';
      this.statusText = this.percentage + '% Repaid';
    } else {
      this.statusText = 'Loan ' + this.statusText;
    }
  },
};
</script>

<style scoped>
span {
  float: right;
  font-weight: 700;
  margin-top: 50px;
}
span.duedate {
  float: left;
  font-weight: normal;
}
</style>
