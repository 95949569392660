<template>
  <div
    class="callout warning"
    v-if="$store.getters.isAuthenticated && !$store.getters.isActive"
  >
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    <p>Your account is blocked.</p>
  </div>
  <h2 class="pageTitle">Loan History</h2>
  <table class="loanTable">
    <thead>
      <tr>
        <th class="sorting">Date</th>
        <th class="sorting">Loan ID</th>
        <th class="sorting">Loan Amount</th>
        <th class="sorting">Interest</th>
        <th class="sorting">Duration (Months)</th>
        <th class="sorting">Repayment Amount</th>
        <th class="sorting">Amount Paid</th>
        <th class="sorting">Balance</th>
        <th class="sorting">Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="loan in loans" :key="loan.id">
        <td>{{ loan.dateCreated }}</td>
        <td>
          <router-link :to="`/dash/${loan.id}`">{{
            loan.loanId || loan.id
          }}</router-link>
        </td>
        <td class="number">{{ loan.amount }}</td>
        <td class="number">{{ loan.interestAmount }}</td>
        <td class="number">{{ loan.duration }}</td>
        <td class="number">{{ loan.repaymentAmount }}</td>
        <td class="number">{{ loan.amountPaid }}</td>
        <td class="number tred">{{ loan.balanceAmount }}</td>
        <td :class="loan.statusClass">{{ loan.statusText }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Util from '../util/Util';
import LoanService from '../services/LoanService';
export default {
  name: 'LoanHistory',
  data() {
    return {
      loans: [],
    };
  },
  mounted() {
    if (this.$store.state.currentUser.merchant_details) {
      LoanService.getMerchantLoanRequests(
        this.$store.state.currentUser.merchant_details.external_id
      ).then(({ data }) => {
        this.loans = data;
        Util.formatRequestsForDisplay(this.loans);
      });
    }
  },
  created() {
    this.$store.dispatch('setCurrentBreadcrumb', 'Loan History');
  },
};
</script>
