import axios from 'axios';
import store from '@/store';

// create axios
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
  timeout: 50000, // timeout,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept',
  },
});

service.showLoading = true;

const err = (error) => {
  const { status, data } =
    typeof error.response != 'undefined' ? error.response : error;
  const { errors } = data;
  let message = [];
  for (let field in errors) {
    message.push(errors[field]);
  }
  switch (status) {
    case 400:
      // window._VMA.$emit('SHOW_SNACKBAR', {
      //     show: true,
      //     text: 'Bad Request ' + data.message,
      //     color: 'warning',
      // });
      break;

    case 422:
      // window._VMA.$emit('SHOW_SNACKBAR', {
      //     show: true,
      //     text: message,
      //     color: 'red',
      // });

      break;

    case 401:
      // window._VMA.$emit('AUTH_FAIELD', {
      //     show: true,
      //     text: 'Invalid credentials',
      //     color: 'red',
      // });
      store.dispatch('logout');
      window.location.href = store.getters.getRedirectUrl;
      break;

    case 403:
      // window._VMA.$emit('ACESS_DENIED');
      break;
    case 500:
      // window._VMA.$emit('SERVER_ERROR', {
      //     text: 'Success ' + data.message,
      // });
      break;

    default:
      break;
  }
  store.dispatch('loading', false);
  return Promise.reject(error);
};

// request interceptor

service.interceptors.request.use((config) => {
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Content-Type'] = 'application/json';
  config.headers['Authorization'] = 'Bearer ' + store.getters.getAccessToken;
  if (service.showLoading) {
    store.dispatch('loading', true);
  }
  service.showLoading = true;
  return config;
}, err);

// response interceptor

service.interceptors.response.use(({ data, config }) => {
  if (['put', 'post', 'delete', 'patch'].includes(config.method) && data.meta) {
    window._VMA.$emit('SHOW_SNACKBAR', {
      text: data.meta.message,
      color: 'success',
    });
  }
  if (data.error !== undefined) {
    window._VMA.$emit('API_FAILED', data.error);
  }
  store.dispatch('loading', false);
  return data;
}, err);

export default service;
