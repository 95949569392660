<template>
  <div class="loanrepay">
    <div class="callout warning" v-if="errorMessage.length">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>

      <p>{{ errorMessage }}</p>
      <span class="close close-button" @click="clearErrors">x</span>
    </div>
    <div class="callout warning" v-if="alertMessageType == 'warning'">
      <i
        v-if="alertMessageType == 'warning'"
        class="fa fa-warning"
        aria-hidden="true"
      ></i>

      <p>{{ alertMessage }}</p>
      <span class="close close-button" @click="clearAlertMessage">x</span>
    </div>
    <div class="callout success" v-if="alertMessageType == 'success'">
      <i
        v-if="alertMessageType == 'success'"
        class="fa fa-check-square-o"
        aria-hidden="true"
      ></i>

      <p>{{ alertMessage }}</p>
      <span class="close close-button" @click="clearAlertMessage">x</span>
    </div>
    <div class="row medium-uncollapse">
      <h2 class="pageTitle">Pay Loan #{{ currentRequest.loan.id }}</h2>
      <div class="column medium-6 pdleft">
        <h4>
          Loan balance - {{ currentRequest.schedule.currency }}
          {{ currentRequest.balance }}
        </h4>
        <p>How much would you like to pay?</p>
        <input
          type="text"
          placeholder="Enter Amount"
          class="input-block-level input-large input-group-field"
          v-model="formattedAmount"
          @input="formatAmount"
        />
      </div>
      <div class="column medium-6 pdright">
        <LoanStatus
          v-if="currentRequest != null"
          :loanRequest="currentRequest"
          :showStatus="false"
        />
      </div>
    </div>
    <div class="row medium-uncollapse">
      <div class="mrgl-0 mrgr-0 bord-b5">
        <h4>Choose Payment Option</h4>
        <br />
        <div
          v-if="settlmentEnabled"
          :class="settlementClass"
          @click="() => selectPaymentOption('settlement')"
        >
          <h4 class="payment-option-title">Settlement</h4>
          <hr class="payment-option-line" />
          <img
            src="/assets/images/settlement_logo.png"
            class="settlement-logo"
          />
          <p class="payment-otion-desc">
            Use your Pesapal account balance to complete payment
          </p>
        </div>
        <!-- 
        <br />
        <div
          v-if="settlmentEnabled"
          :class="settlementClass"
          @click="() => selectPaymentOption('settlement')"
        >
          <h4 class="payment-option-title">Settlement</h4>
          <hr class="payment-option-line" />
          <img
            src="/assets/images/settlement_logo.png"
            class="settlement-logo"
          />
          <p class="payment-otion-desc">
            Use your Pesapal account balance to complete payment
          </p>
        </div>
        -->
        <div
          v-if="onlineEnabled"
          :class="onlineClass"
          v-scroll-to="{ el: '#online_payment' }"
          @click="() => selectPaymentOption('online')"
        >
          <h4 class="payment-option-title">Online</h4>
          <hr class="payment-option-line" />
          <img src="/assets/images/mpesa_logo.png" class="mpesa-logo" />
          <img src="/assets/images/payment_card.png" class="payment-card" />
          <p class="payment-otion-desc">
            Use your Mpesa or card to complete payment
          </p>
        </div>
        <div
          :class="offlineClass"
          @click="() => selectPaymentOption('offline')"
        >
          <h4 class="payment-option-title">Offline</h4>
          <hr class="payment-option-line" />
          <img src="/assets/images/bank_logo.png" class="settlement-logo" />
          <p class="payment-otion-desc">
            Upload a bank deposit slip for your payment
          </p>
        </div>
      </div>
    </div>

    <div class="row medium-uncollapse mrgbt-1 pdl-3" v-if="isSettlement()">
      <h6>
        Pesapal settlement balance is
        <b>{{ currentRequest.loan.currency }} 43,712.34</b>
        <!-- This value should be picked from some API-->
      </h6>
      <div class="controls">
        <input
          type="button"
          class="button"
          value="PROCEED TO PAY"
          @click="() => makePayment('settlement')"
        />
      </div>
      <div class="controls">
        <input
          type="button"
          class="button secondary"
          value="CANCEL"
          @click="cancelRepayment"
        />
      </div>
    </div>
    <div
      class="row medium-uncollapse mrgbt-1 pdl-3"
      id="online_payment"
      v-if="isOnlinePayment()"
    >
      <iframe
        :ref="online_payment"
        v-if="paymentLink"
        border="none"
        width="800"
        height="600"
        :src="paymentLink"
      ></iframe>
    </div>
    <div
      class="row medium-uncollapse mrgbt-1 pdl-3 pdtb-1"
      v-if="isOfflinePayment()"
    >
      <div class="column medium-6 pdleft off-payments">
        <h4>Use a bank transfer to complete the payment</h4>
        <b>Enter Bank Transfer Details</b>
        <div class="control-group">
          <label class="control-label">Payment Method:</label>
          <div class="controls">
            <select v-model="paymentMethod">
              <option value="cheque">Cheque</option>
              <option value="rtgs">RTGS</option>
              <option value="eft">EFT</option>
              <option value="deposit">Deposit</option>
              <option value="app">Mobile App</option>
              <option value="ussd">USSD</option>
            </select>
          </div>
        </div>
        <div class="control-group">
          <label class="control-label">Payment Date:</label>
          <div class="controls">
            <Datepicker v-model="paymentDate" :format="format" autoApply />
            <span class="error">{{ paymentDateError }}</span>
          </div>
        </div>
        <div class="control-group">
          <label class="control-label">Transaction Reference:</label>
          <div class="controls">
            <input class="input-block-level" type="text" v-model="reference" />
            <span class="error">{{ transactionReferenceError }}</span>
          </div>
        </div>
        <div class="control-group">
          <label class="control-label">Notes:</label>
          <div class="controls">
            <textarea
              v-model="notes"
              class="input-block-level"
              style="margin-bottom: 0px"
            ></textarea>
          </div>
        </div>
        <div class="control-group" v-if="!isMobileOfflinePayment()">
          <label class="control-label">Deposit/Transfer slip:</label>
          <div class="controls">
            <div>
              <input type="file" ref="file" v-on:change="handleFileUpload" />
            </div>
            <span class="error">{{ fileUploadError }}</span>
          </div>
        </div>
        <div class="controls">
          <input
            type="button"
            class="button"
            value="PROCEED TO PAY"
            @click="makeOfflineRepayment"
          />
        </div>
        <div class="controls">
          <input
            type="button"
            class="button secondary"
            value="CANCEL"
            @click="cancelRepayment"
          />
        </div>
      </div>
      <div class="column medium-6 pdright">
        <p>
          You can transfer using RTGS, EFT, cheque or counter deposit to the
          bank below
        </p>
        <table>
          <tr>
            <th>Bank Name:</th>
            <td>NCBA BANK</td>
          </tr>
          <tr>
            <th>Bank Branch:</th>
            <td>NIC House</td>
          </tr>
          <tr>
            <th>Account Name:</th>
            <td>PESAPAL CREDIT</td>
          </tr>
          <tr>
            <th>Account Number:</th>
            <td>1530870103</td>
          </tr>
          <tr>
            <th>Bank Code:</th>
            <td>07000</td>
          </tr>
          <tr>
            <th>Swift Code:</th>
            <td>CBAFKENX</td>
          </tr>
        </table>
        <div>
          MPESA
          <ol></ol>
          <li>Go to M-PESA service on your phone.</li>
          <li>Select Lipa na M-PESA</li>
          <li>Enter 880100 as Business Number</li>
          <li>Enter 1530870103 as Account Number</li>
          <li>Enter Amount you wish to top up.</li>
          <li>Enter your Mpesa Pin</li>
          <li>Confirm and send Transaction.</li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import Util from '../util/Util';
import FileService from '../services/FileService';
import LoanService from '../services/LoanService';
import MerchantService from '../services/MerchantService';
import LoanStatus from '../components/loans/LoanStatus.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const paymentOptionClass = 'payment-option';

export default {
  setup() {
    const date = ref(new Date());
    const format = (date) => {
      const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
      const month =
        date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      date,
      format,
    };
  },
  name: 'RepayLoan',
  components: {
    LoanStatus,
    Datepicker,
  },
  data() {
    return {
      file: null,
      amount: null,
      formattedAmount: null,
      paymentMethod: 'cheque',
      paymentDate: null,
      currentRequest: {},
      reference: null,
      notes: null,
      paymentType: 'offline',
      mpesa: true,
      visa: false,
      paymentLink: null,
      alertMessage: '',
      alertMessageType: '',
      errorMessage: '',
      paymentDateError: '',
      transactionReferenceError: '',
      fileUploadError: '',
    };
  },
  computed: {
    settlementClass() {
      return this.isSettlement()
        ? paymentOptionClass + ' active'
        : paymentOptionClass;
    },
    onlineClass() {
      return this.isOnlinePayment()
        ? paymentOptionClass + ' active'
        : paymentOptionClass;
    },
    offlineClass() {
      return this.isOfflinePayment()
        ? paymentOptionClass + ' active'
        : paymentOptionClass;
    },
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    makePayment(paymentType) {
      console.log(paymentType);
    },
    formatAmount() {
      if (this.formattedAmount) {
        var value = this.formattedAmount;
        this.formattedAmount = this.extractAmount();
        if (isNaN(this.formattedAmount)) {
          this.formattedAmount = value;
        } else {
          this.formattedAmount = Util.formatNumber(
            this.formattedAmount,
            this.currentRequest.schedule.currency
          );
        }
      }
    },
    extractAmount() {
      var amount = this.formattedAmount.replaceAll(
        this.currentRequest.schedule.currency,
        ''
      );
      amount = amount.replaceAll(' ', '');
      return amount.replaceAll(',', '');
    },
    makeOnlinePayment() {
      var amount = 1; //this.extractAmount();
      MerchantService.makePayment({
        loan_id: this.currentRequest.loan.id,
        amount: parseFloat(amount),
        currency: this.currentRequest.loan.currency,
      })
        .then((data) => {
          this.paymentLink = data.payment_url;
          this.$scrollTo('#online_payment', 1000, {});
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data) {
              if (
                error.response.data.errors &&
                error.response.data.errors.length
              ) {
                this.errorMessage = error.response.data.errors[0];
              } else if (error.response.data.message) {
                this.errorMessage = error.response.data.message;
              }
            } else if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors[0]
            ) {
              this.errorMessage = error.response.data.errors[0];
            }
          }
          if (this.errorMessage.length === 0) {
            this.errorMessage =
              'The server returned an error. Please contact your System Administrator';
          }
        });
    },
    makeOfflineRepayment() {
      if (this.validateRepayment()) {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('type', 'receipt');
        if (this.isMobileOfflinePayment()) {
          this.submitOfflineRepayment();
        } else {
          FileService.upload(formData)
            .then(({ file_path }) => {
              this.submitOfflineRepayment(file_path);
            })
            .catch(() => {
              this.errorMessage = 'File upload failed';
            });
        }
      }
    },
    isMobileOfflinePayment() {
      return ['app', 'ussd'].includes(this.paymentMethod);
    },
    submitOfflineRepayment(file_path = null) {
      var amount = this.extractAmount();
      LoanService.submitOfflineRepayment({
        loan_id: this.currentRequest.loan.id, //this is an active loan, so this value is expected to exist
        reference: this.reference,
        amount: parseFloat(amount),
        currency: this.currentRequest.loan.currency,
        payment_method: this.paymentMethod,
        payment_date: this.paymentDate,
        notes: this.notes,
        receipt_url: file_path,
      })
        .then(() => {
          this.redirectToDash();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data) {
              if (
                error.response.data.errors &&
                error.response.data.errors.length
              ) {
                this.errorMessage = error.response.data.errors[0];
              } else if (error.response.data.message) {
                this.errorMessage = error.response.data.message;
              }
            } else if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors[0]
            ) {
              this.errorMessage = error.response.data.errors[0];
            }
          }
          if (this.errorMessage.length === 0) {
            this.errorMessage =
              'The server returned an error. Please contact your System Administrator';
          }
        });
    },
    validateRepayment() {
      this.clearErrors();
      if (!this.formattedAmount) {
        this.errorMessage = 'Please provide repayment amount';
        return false;
      } else {
        var amount = this.extractAmount();
        if (isNaN(amount)) {
          this.errorMessage = 'Repayment amount must be a number';
          return false;
        }
      }
      if (!this.paymentMethod) {
        this.errorMessage = 'Please provide payment method';
        return false;
      }
      if (!this.paymentDate) {
        this.paymentDateError = 'Please provide payment date';
        return false;
      }
      if (!this.reference) {
        this.transactionReferenceError = 'Please provide transaction reference';
        return false;
      }
      if (!this.file && !this.isMobileOfflinePayment()) {
        this.fileUploadError = 'Please attach a Deposit/Transfer slip';
        return false;
      }
      return true;
    },
    selectPaymentOption(option) {
      this.paymentType = option;
      if (option == 'online') {
        this.makeOnlinePayment();
      }
    },
    cancelRepayment() {
      this.redirectToDash();
    },
    redirectToDash() {
      this.$store.dispatch('setCurrentLoan', null);
      this.$router.push('/dash');
    },
    isSettlement() {
      return this.paymentType === 'settlement';
    },
    isOnlinePayment() {
      return this.paymentType === 'online';
    },
    isOfflinePayment() {
      return this.paymentType === 'offline';
    },
    selectOnlinePaymentType(type) {
      this.mpesa = type === 'mpesa';
      this.visa = type === 'visa';
    },
    clearErrors() {
      this.errorMessage = '';
      this.fileUploadError = '';
      this.paymentDateError = '';
      this.transactionReferenceError = '';
    },
    clearAlertMessage() {
      this.alertMessage = '';
      this.alertMessageType = '';
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('OrderTrackingId')) {
      var data = {
        OrderTrackingId: urlParams.get('OrderTrackingId'),
        OrderMerchantReference: urlParams.get('OrderMerchantReference'),
      };
      MerchantService.processOnlinePayment(data).then((response) => {
        console.log(response);
        this.alertMessage = response.message;
        if (response.status == 200) {
          this.alertMessageType = 'success';
        } else if (response.status == 400) {
          this.alertMessageType = 'warning';
        } else {
          this.alertMessageType = 'warning';
        }
      });
    }
    console.log(urlParams.has('OrderTrackingId'));
    //we expect this to be set
    this.currentRequest = this.$store.getters.getCurrentLoan;
    this.currentRequest.balance = Util.formatNumber(
      this.currentRequest.loan.balance
    );
    var nextIntallment = this.currentRequest.repaymentSchedule.filter(
      (x) => !x.complete
    )[0];
    if (nextIntallment) {
      this.formattedAmount = `${this.currentRequest.schedule.currency} ${nextIntallment.total_outstanding_for_period}`;
    } else {
      this.formattedAmount = `${Util.formatNumber(
        this.currentRequest.schedule.repayment_schedule[0].total_outstanding,
        this.currentRequest.schedule.currency
      )}`;
    }
    this.$store.dispatch('setCurrentBreadcrumb', 'Repay Loan');
  },
};
</script>

<style scoped>
.spacer {
  height: 50px;
}
.payment-option {
  height: 180px;
  width: 220px;
  left: 0px;
  top: 0px;
  border-radius: 5px 5px 0 0;
  border: 1px solid #cccccc;
  border-bottom: medium none !important;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.payment-option.active {
  background: #eeeeee;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
.payment-option-title {
  position: relative;
  margin: 0.5rem 0;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #000000;
}
.payment-option-line {
  height: 0px;
  left: 0px;
  top: 43px;
  border-color: #cccccc;
}
.settlement-logo {
  height: 32px !important;
  left: 86.24px !important;
  border-radius: 3px;
  position: relative;
}
.mpesa-logo {
  width: 60px !important;
  left: 63px !important;
  border-radius: 3px;
  position: relative;
}
.payment-card {
  height: 32px !important;
  width: 42.23px !important;
  left: 70px !important;
  border-radius: 3px;
  position: relative;
}
.payment-otion-desc {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0 1rem;
  color: #000000;
}
.controls {
  display: inline-block;
  margin-right: 20px;
}
.online-option {
  height: 60px !important;
  padding: 0.2rem 0;
}
.online-payment-option {
  display: inline;
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 0.7rem 0.8rem;
  margin-right: 20px;
}
.phone-section {
  width: 800px;
  border: 1px solid #dadada;
}
.phone-section > div:first-child {
  background-color: #eeeeee;
  display: inline-block;
  padding: 5px;
  border: 1px solid #dadada;
  border-width: 0px 1px 1px 0px;
  margin-right: 10px;
}
.phone-section .input-group-field {
  width: auto;
  display: inline;
  height: 37px;
  margin-top: -5px;
  border-width: 0px 0.5px 0.5px 0px;
}
.phone-section .phone-icon {
  background-color: #eeeeee;
  display: inline-block;
  margin-left: 20px;
  margin-top: 20px;
  padding: 5px;
  border: 1px solid #dadada;
}
.phone-section i {
  font-size: 20px;
}
.area-code {
  width: 80px;
}
.control-label {
  min-width: 200px;
  text-align: right;
  padding-right: 5px;
}
.control-label,
.controls {
  display: inline-block;
}
.controls select {
  display: inline;
  width: auto;
  margin-right: 5px;
}
table {
  width: auto;
  border-width: 0px;
  border-collapse: unset;
}
table th:first-child {
  text-align: right;
}
th,
td {
  border-width: 0px;
  padding: 5px;
}
tr {
  background-color: white !important;
}
.dp__main {
  margin-bottom: 1rem;
}
.error {
  margin-top: -1.1rem;
  position: absolute;
}
iframe {
  border: none;
}
</style>
