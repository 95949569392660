<template>
  <h2 v-if="showStatus" class="pageTitle">Status:</h2>
  <h2 v-if="showStatus" :class="statusClass" style="display: inline">
    {{ statusText }}
  </h2>
  <table>
    <tr>
      <th>Currency:</th>
      <td>
        {{ loanRequest.schedule.currency }}
      </td>
    </tr>
    <tr>
      <th>Date Created:</th>
      <td>
        {{ loanRequest.dateCreated }}
      </td>
    </tr>
    <tr>
      <th>Loan Amount:</th>
      <td>
        {{ loanRequest.amount }}
      </td>
    </tr>
    <tr>
      <th>Amount Repaid:</th>
      <td>
        {{ loanRequest.amountPaid }}
      </td>
    </tr>
    <tr>
      <th>Balance:</th>
      <td>
        {{ outstandingBalance }}
      </td>
    </tr>
    <tr>
      <th>Duration (Months):</th>
      <td>
        {{ loanRequest.duration }}
      </td>
    </tr>
    <tr>
      <th>Monthly Payment:</th>
      <td>
        {{ monthlyPayment }}
      </td>
    </tr>
  </table>
</template>

<script>
import Util from '../../util/Util';

export default {
  name: 'LoanStatus',
  data() {
    return {
      statusText: 'Pending',
      statusClass: 'pending',
      amount: this.loanRequest.amount,
    };
  },
  computed: {
    monthlyPayment() {
      return Util.formatNumber(
        this.loanRequest.schedule.repayment_schedule[0].total_outstanding
      );
    },
    outstandingBalance() {
      if (this.loanRequest.loan) {
        return Util.formatNumber(this.loanRequest.loan.balance);
      }
      return Util.formatNumber(this.loanRequest.totalRepaymentExpected);
    },
  },
  props: {
    loanRequest: {
      type: Object,
      required: true,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    let status = this.loanRequest.status;
    this.percentage = this.loanRequest.percentRepaid || 0;
    Util.setStatusClass(this, status);
  },
};
</script>

<style scoped>
h3 {
  display: inline;
  margin-right: 5px;
}
table {
  width: auto;
  border-width: 0px;
}
table th:first-child {
  text-align: right;
  color: #868686;
}
th,
td {
  border-width: 0px;
  padding: 5px;
}
.status-header {
  display: inline;
  margin-right: 5px;
}
</style>
